@import "../node_modules/ngx-chips/core/styles/core/_core.scss";

$foundation-primary: #27ae60;
$foundation-primary-dark: darken($foundation-primary, 10%);

// this is the container's theme
$foundation-theme: (
    container-border-bottom: 1px solid $foundation-primary
);

// this is the tag's theme
$foundation-tag-theme: (
    background: $foundation-primary,
    background-focused: $foundation-primary-dark,
    background-active: $foundation-primary-dark,
    background-hover: $foundation-primary-dark,
    color: #fff,
    color-hover: #fff,
    border-radius: 2px
);

// this is the delete icon's theme
$foundation-icon-theme: (
    fill: #fff,
    fill-focus: #eee,
    transition: all 0.35s
);

// apply theme to the container
.ng2-tag-input.foundation-theme {
    @include tag-input-theme($foundation-theme);
}

// apply theme to the tags
.ng2-tag-input.foundation-theme tag {
    @include tag-theme($foundation-tag-theme);
}

// apply theme to the delete icon
.ng2-tag-input.foundation-theme tag delete-icon {
    @include icon-theme($foundation-icon-theme);
}