/* widget */

.widget.peity-chart .peity {
    width: 100%;
    height: 75px;
}

.widget.card-body .new-task .fade-in-primary .cr {
    margin-top: 12px;
}

.ui.table td.active, .ui.table tr.active {
    box-shadow: 0 0 0 rgba(0,0,0,.87) inset;
    background: #E0E0E0!important;
    color: #fff!important;
}

.widget .calendar:focus,
.widget .calendar:active {
    outline: none !important;
}

.widget .table td.active,
.widget .table tr.active {
    box-shadow: 0 0 0 rgba(0,0,0,.87) inset;
    background: #0073aa!important;
    color: #fff !important;
    /*border-radius: 5px;*/
}

.widget td.link.today,
.widget td.link.active.today {
    background-color: #4680ff  !important;
    color: #fff !important;
    text-align: center;
    /*border-radius: 5px;*/
}
.widget .table th{
    width: calc(100% / 14);
    border: none !important;
    text-align: center;
}
.widget .table  thead tr + tr{
    border-top: 1px solid #ededed;
    border-bottom: 3px solid #ededed;
}
.widget .table td  {
    border-top: 1px solid transparent;
    text-align: center;
    margin: 10px 5px;
    padding: 6px;
    width: 35px;
    height: 35px;
    display: inline-block;
    border-radius: 50%;
}

.widget .table td.link  {
    cursor: pointer;
}

.widget .day {
    color: #757575;
}

.widget .table tr{
    justify-content: space-evenly;
    display: flex;
}

.widget .table.month td,
.widget .table.year td{
    padding: 12px;
    width: 50px;
    height: 50px;
}

.widget .table.year td {
    padding: 13px 10px;
}

.widget .table.day td:first-child,
.widget .table.day td:last-child,
.widget .table.day th:first-child,
.widget .table.day th:last-child{
    color:#ff4040;
}
.widget .table thead tr:first-child th {
    color: #000;
    width: 100%;
    font-size: 25px;
}
.widget .table thead tr:first-child {
    background-color: #fafafa;
}
.widget .table thead tr:first-child th{
    color: #757575!important;
}
.widget .table td.adjacent.disabled{
    opacity: 0.3;
}

.widget table tbody tr:last-child td.today{
    width: 20%;
    border-radius: 6px;
    background-color: #0073aa!important;
}
