$border-color: rgba(0,0,0,0.2);
$border-dark: 1px solid $border-color;
$text-color: #212529;
$text-muted-color: #444;
$blue-sheyla: #79C9D5;
$blue-sheyla-dark: darken($blue-sheyla, 20%);
$blue-sheyla-darker: darken($blue-sheyla, 40%);
$red-sheyla: #E30613;

a {
  color: $blue-sheyla-dark;

  &:hover {
    color: $blue-sheyla-darker;
  }
}

.pcoded-main-container {
  background: #f9fafb;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
  background-color: $blue-sheyla;
  border-color: $blue-sheyla;
}

.btn-outline-primary {
  border-color: $blue-sheyla;
  color: $blue-sheyla-dark;
}

.btn-info {
  background-color: $blue-sheyla-dark;
  border-color: $blue-sheyla-dark;
}

.text-primary {
  color: $blue-sheyla-dark !important;
}

.card {
  background: #fff;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;

  .ngx-datatable.material {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0,0.3);
  }

  &.card-border-primary {
    border-top: 4px solid #79C9D5;
  }
}

.accordion > .card {
  margin-bottom: 0;
}

.pcoded {
  .pcoded-header[header-theme="theme-white"] {
    background: #79C9D5;
    color: #fff;
  }
  .pcoded-header .navbar-logo[navbar-theme="theme-white"] {
    background-color: #79C9D5;
  }
  .pcoded-navbar[active-item-theme="theme-white"] .pcoded-item > li.active > a:before {
    border-left-color: #E30613 !important;
  }
  .pcoded-navbar {
    .pcoded-inner-navbar {
      &.main-menu {
        border-right: 1px solid rgba(0,0,0,.1)
      }
    } 
    .pcoded-item {

      & > li {
        & > a {
          padding: 5px 15px;
  
          & > .pcoded-micon {
            font-size: 18px;
            padding-right: 10px;
          }
        }
      }

      // Different colors for elements
      &.pcoded-micon-color-0 > li > a > .pcoded-micon {
        color: #3498db;
      }
      &.pcoded-micon-color-1 > li > a > .pcoded-micon {
        color: #1abc9c;
      }
      &.pcoded-micon-color-2 > li > a > .pcoded-micon {
        color: #f39c12;
      }
      &.pcoded-micon-color-3 > li > a > .pcoded-micon {
        color: #34495e;
      }
      &.pcoded-micon-color-4 > li > a > .pcoded-micon {
        color: #e74c3c;
      }
      &.pcoded-micon-color-5 > li > a > .pcoded-micon {
        color: #9b59b6;
      }
      &.pcoded-micon-color-6 > li > a > .pcoded-micon {
        color: #2ecc71;
      }
      &.pcoded-micon-color-7 > li > a > .pcoded-micon {
        color: #d35400;
      }
    }
  }
}

#printable-area .card {
  box-shadow: none !important;
}

.text-muted {
  color: $text-muted-color !important;
}

// Borders
.sub-title {
  border-bottom: $border-dark !important;
}

.border {
  border: $border-dark !important;
}

// Forms
.form-control {
  border-color: $border-color;
  border-width: 1px;
}

// Data table
.ngx-datatable {
  &.material {
    border: $border-dark;

    .datatable-header {
      border-bottom: $border-dark;

      .datatable-header-cell {
        color: $text-color;
      }

      .resize-handle {
        border-right: $border-dark;
      }
    }

    .datatable-footer {
      color: $text-color;
      border-top: $border-dark;
    }
  }
}
