.blocks {
  position: relative;
  &.active:after {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    width: 6px;
    height: 50px;
    background-color: #E30613;
  }
}

.builder-block {
  background-color: #FFFFFF;
  max-width: 190px;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
  &.active {
    background:#79C9D5;
    border: 1px solid #000;
  }
}

.block-size-36 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.block-size-78 {
  padding-top: 19px;
  padding-bottom: 19px;
}

.block-size-98 {
  padding-top: 39px;
  padding-bottom: 39px;
}

.block-size-118 {
  padding-top: 49px;
  padding-bottom: 49px;
}

.block-size-116 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.block-size-114 {
  padding-top: 47px;
  padding-bottom: 47px;
}

.block-size-66 {
  padding-top: 23px;
  padding-bottom: 23px;
}

// Drag and Drop Styles
.builder-dnd-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

}
.builder-dnd-handler {
  position: absolute;
  right: 12px;
  bottom: 8px;
  cursor: move;
  font-size: 16px;
  color: #666;
}

.cdk-drag-preview .builder-block {
  background: rgba(255, 255, 255, 0.7);
}

.cdk-drag-placeholder .builder-block {
  background: #ccc;
  border: dotted 3px #999;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  min-height: 60px;
}

// --
// -- Acordeón del Website
// --
$first-el-height: 85%; // Altura del borde para el primer elemento del menú (Home)
$top-adjustment: 10px; // Altura de línea vertical que señala al nombre de cada página en el árbol
$left-border-position: -12px; // Posición izquierda del borde del árbol
$border-left-style: 1px solid #000000; // Estilo para el borde que forma las líneas del árbol

.website-container {
  .accordion {
    .card {
      border: none !important;
      box-shadow: none !important;
      margin-bottom: 14px;
      &.open::after {
        border-bottom:1px solid #E30613;
      }
      // Mapa de Sitio
      &:first-child {
        .card-body {
          background-color: #ffffff;
        }
        margin-bottom: 0px;
        &::before {
          content: '';
          position: absolute;
          height: $first-el-height;
          padding-top: 0;
        }
      }
      &:first-child::after {
        content: '';
        position: absolute;
        width: 0;
      }
      &:nth-last-child(1) {
        &::before {
          bottom: inherit;
          top: 0;
          height: $top-adjustment;
          padding-top: 0;
        }
      }
      &::before {
        content: '';
        position: absolute;
        bottom: -20px;
        left: $left-border-position;
        height: calc(100% + 20px);
        border-left: $border-left-style;
      }
      &::after {
        content:'';
        position: absolute;
        border-bottom: 1px solid #000000;
        left: $left-border-position;
        top: $top-adjustment;
        width: 12px;
      }
      // Fin de Mapa de Sitio
      .card-header {
        background-color: #FFFFFF;
        border: none;
        padding: 0;
        position: relative;
          span {
          color: #000;
          font-size: 0;
          margin-top: 0;
          position: relative;
          right: 0;

          &.visible {
            font-size: inherit;
          }
        }
      }
      .card-body {
        background-color: #E4F6F8;
        padding:0;
      }
      h5 {
        display: block;
        width: 100%;
      }
      .btn-link {
        color: #000000;
        display: block;
        font-size: 12px;
        font-family: 'Roboto', sans-serif;
        text-decoration: none;
        text-transform: none;
        padding: 0;
        width: 100%;
      }
      .page-name {
        text-transform:capitalize;
        &:hover {
          cursor: text;
        }
        &.edit {
          border: 1px solid #CED4DA;
          padding: 3px 0px 3px 8px;
        }
      }
    }

    /* Animation Accordion effect */
    .collapse {
      transition: max-height .655s, opacity .355s ease-in-out;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      display: block !important;

      &.show {
        max-height: 100rem;
        opacity: 1;
      }
    }
  }

}

// --
// -- Popover
// --
.scroll-on-y {
  max-height: calc(100vh - 50px);
  overflow-y: auto;
}


// --
// -- Blocks popover container
// --
.blocks-popover-container {
  border: 1px solid #CED4DA;
  box-sizing: border-box;
  margin-left: 30px;
  font-family: 'Roboto';
  padding: 12px 20px;
  max-width: 450px;
  box-shadow: 2px 2px 6px 2px rgba(0,0,0,0.25);
  border-radius: 0%;
  width: 100%;
  z-index: 1050;

  .dots-pop-class {
    .popover-body {
      min-height: auto !important;

      div {
        border: 1px solid #CED4DA;
        box-sizing: border-box;
        color: #666666;
        line-height: 200%;
        font-size: 11px;
        padding-left: 10px;
        cursor: pointer;
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }

  .popover-header {
    background-color: #FFFFFF;
    font-family: 'Roboto';
    font-size: 14px;
    border: none;
    padding: 0px 0px 25px 0px;
  }

  .popover-body {
    background-color: #FFFFFF;
    font-size: 12px;
    color:#000000;
    font-family: 'Roboto';
    padding: 0;
    min-height: 210px;
  }

  .form-control {
    color: #495057;
    height: calc(1.5rem + 0px) !important;
    padding: 2px 15px;
    font-size: 12px;
  }

  .arrow {
    display: none !important;
  }

  .title {
    font-size: 14px;
  }

  .palette {
    display: flex;
    margin: 0 auto;
  }

  .square {
    width: 33px;
    height: 33px;
    cursor: pointer;
    margin-right: 18px;
    border: 1px solid #333;
    border-radius: 0.25rem;

    &.active,
    &:focus {
      border-color: #0a58ca;
      box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 50%);
    }
  }

  .btn-close {
    max-width: 20px;
    font-size: 14px;
    margin-left: auto;
    cursor: pointer;
  }
}

.blocks-popover-container-wide {
  max-width: 800px;

  &.fixed {
    width: 800px;
  }
}

.blocks-popover-container-medium {
  max-width: 600px;
}

.blocks-popover-container-half-wide {
  max-width: 400px;
}

@media(max-width:767px) {
  .blocks-popover-container {
    display: none;
  }
}

.typeahead-container {
  position: relative;

  .dropdown-menu {
    font-size: 12px;
  }
}
