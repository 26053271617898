.diamond-shape {
  transform: rotate(45deg);
  width: 1rem;
  height: 1rem;
}

.circle-shape {
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
}
