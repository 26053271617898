@import 'notifications';
@import 'accordion';
@import 'tabs';
@import 'forms';
@import 'tables';
@import 'widget';
@import 'color-picker';
@import 'steps';
@import 'ngx_chips_foundation_theme';
@import 'plan_cards';
@import 'products_menu';
@import 'new_site_builder';
@import 'subscriptions';
@import 'website';
@import 'rate_card';
@import 'builder_faq_accordion';
@import 'popover-slider-icon';
@import 'shapes';

/* components/task end */
.card-body .new-task .fade-in-primary .cr {
    margin-top: 0;
}

.task-right .accordion-block p {
    margin-bottom: 1rem;
}

.task-right .media-left {
    padding-right: 20px;
    padding-left: 2px;
}
.filter-bar .navbar .navbar-nav .dropdown-menu{
    position: absolute;
}
/* components/task end */

/* components/editor/quill editor */
.quill-editor {
    min-height: 16em;
    max-height: 20em;
    overflow-y: auto;
}
/* quill editor end*/


.dropdown-toggle.no-icon::after {
    display: none;
}

.card .card-body.assign-user .dropdown-menu {
    top: 100%;
}

/* components/file-upload/ start */
ngb-progressbar .progress-bar {
    height: 1rem !important;
    color: #fff;
}

.ngb-drop-zone {
    min-height: 1.5rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border: dashed 1px;
    border-radius: 0.125rem;
}
/* components/file-upload/ end */

/* map start */
agm-map, vector_map {
    width: 100%;
    height: 350px;
    display: flex;
}

.jqvmap-label, #jqstooltip {
    position: absolute;
    display: none;
    border: solid 1px #CDCDCD;
    border-radius: 3px;
    background: #292929;
    color: white;
    font-family: sans-serif, Verdana;
    font-size: smaller;
    padding: 3px;
}

.jqvmap-zoomin {
    position: absolute;
    left: 10px;
    border-radius: 3px;
    background: #292929;
    padding: 4px 10px;;
    color: white;
    cursor: pointer;
    line-height: 20px;
    text-align: center;
    box-sizing: content-box;
    top: 10px;
}

.jqvmap-zoomout {
    position: absolute;
    left: 10px;
    border-radius: 3px;
    background: #292929;
    padding: 4px 10px;;
    color: white;
    cursor: pointer;
    line-height: 20px;
    text-align: center;
    box-sizing: content-box;
    top: 50px;
}
/* map end */

.pcoded[vertical-nav-type="collapsed"] .pcoded-navigatio-lavel {
    display: none;
}

/* animation start */
.main-img {
    position: static !important;
}
/* animation end */

/* authentication start */
.auth-fluid {
    margin: 0 auto;
}
/* authentication end */
