.step-container {
  position: relative;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;

  .step {
    position: absolute;
    top: -8px;
    left: -6px;

    background: #E30613;
    border-radius: 0.8em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 1.6em;
    margin-right: 5px;
    text-align: center;
    width: 1.6em;
  }

  &.circle {
    display: flex;
    border-radius: 35%;
    width: 87px;
    height: 85px;
    padding: 14px;
    margin-bottom: 8px;
    background: #fff;
    border: 2px solid #888;
    color: #666;
    text-align: center;

    img {
      max-width: 100%;
      width: 100%;
      height: auto;
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
  }

  &.selected {
    border-width: 5px;

    .step {
      top: -11px;
      left: -9px;
    }
  }

  &.completed {
    border-color: #79C9D5;

    .step {
      background: #79C9D5;
    }

    img {
      -webkit-filter: none;
      filter: none;
    }
  }
}
