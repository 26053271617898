$text-color: #495057;
$white: #ffffff;
$primary-template: #e30613;
$gray-500: #ced4da;
$black: #000000;
$primary: #999999;

#progressbar {
  margin-bottom: 30px;
  overflow: visible;
  color: lightgrey;
  padding: 0;
  width: 100%;
  margin: 0 auto;
  max-width: 360px;
}

#progressbar .active {
  color: $text-color;
}

#progressbar .active.dot:before {
  color: $white;
}

#progressbar li {
  color: #495057;
  text-align: center;
  font-weight: normal;
  list-style-type: none;
  font-size: 12px;
  width: 32.3%;
  margin: 0;
  float: left;
  position: relative;
}

#progressbar .dot {
  margin: 0;
  outline: none;
}

#progressbar .dot-certified {
  text-align: left;
}
#progressbar .dot-certified:before {
  text-align: center;
  margin-left: 0;
  content: "1";
}

#progressbar .dot-seller:before {
  content: "2";
}

#progressbar .dot-buys {
  text-align: right;
}
#progressbar .dot-buys:before {
  margin-right: 0;
  text-align: center;
  content: "3";
}
#progressbar .dot-buys.two:before {
  content: "2";
}
#progressbar .three:before {
  content: "3";
}
#progressbar .four:before {
  content: "4";
}

#progressbar li span {
  max-width: 60px;
  width: 100%;
  display: block;
  text-align: center;
}

#progressbar li:nth-child(1) span {
  margin-left: -15px;
}

#progressbar li:not(:first-child):not(:last-child) span {
  margin:0 auto;
}

#progressbar li:last-child span {
  margin-left: auto;
  margin-right: -15px;
}

#progressbar li:before {
  border: 1px solid $gray-500;
  width: 30px;
  height: 30px;
  line-height: 26px;
  display: block;
  font-size: 14px;
  color: #999999;
  background: $white;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
  position: relative;
  z-index: 1;
}

#progressbar li:after {
  content: "";
  border: 1px solid $gray-500;
  width: 100%;
  height: 2px;
  background: $white;
  position: absolute;
  left: 0;
  top: 15px;
  z-index: 0;
}

#progressbar li.active:before {
  background: #E30613;
}

@media (max-width: 767px) {
  #progressbar {
    padding-left: 0;
  }
  #progressbar .dot {
    font-size: 13px;
  }
  #progressbar .dot-certified span {
    text-align: center;
    margin-left: 0;
  }
  #progressbar .dot-buys span {
    text-align: center;
    margin-right: 0;
  }
}
