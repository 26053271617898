.login-card {
  .sheyla {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-size: 26px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: black;
    height: 56px;
    line-height: 56px;
    padding: 0 15px;

    img {
      max-width: 60%;
    }
  }

  .sheyla-headline {
    font-family: 'Nunito', sans-serif;
    font-size: 13px;
    font-weight: 600;
    color: #666;
    margin-top: 10px;
    padding: 0 15px;
  }

  .email-label {
    font-size: 1.15rem;
  }
}
