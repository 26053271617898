/* components/forms/advance-elements */
.switch {
    vertical-align: middle !important;
}

.ng2-tag-input {
    border-bottom: 1px solid #0073aa !important;
    border: 1px solid #0073aa;
    padding: 5px !important;
}

.tag__text {
    line-height: 2;
    font-size: 11px;
    font-weight: 600;
}
delete-icon svg{
    height: 13px !important;
    line-height: 1;
    vertical-align: top !important;
    margin-top: 5px;
}
.ng2-tags-container tag{
    padding: 0 5px !important;
    height: 22px !important;

}

delete-icon:hover {
    transform: none !important;
}

.ng2-tag-input__text-input {
    height: auto !important;
}

.bootstrap tag {
    background: #0073aa !important;
}

/* components/forms/advance-validations */
.tooltip-form {
    position: relative;
}

.tooltip-error {
    position: absolute;right: 30px;top: 7px;
}

/* components/forms/picker */
/* bootstrap date-picker start */
.custom-range {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
}
.custom-range.focused {
    background-color: #e6e6e6;
}
.custom-range.range, .custom-range:hover {
    background-color: #0275d8;
    color: white;
}
.faded {
    opacity: 0.5;
}

.custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    border-radius: 0.25rem;
    display: inline-block;
    width: 2rem;
}
.custom-day:hover, .custom-day.focused {
    background-color: #e6e6e6;
}
.weekend {
    background-color: #999;
    border-radius: 1rem;
    color: white;
}
.hidden {
    display: none;
}

@media (min-width: 576px){
    .form-inline .form-group {
        display: block;
        flex: 240px;
    }
}

/* bootstrap date-picker end */

/* material date-picker start */
.md-date-pic {
    position: relative;
    width: 20.5em;
    max-width: 100%;
    border-bottom: 2px solid #ddd;
}
.md-date-pic .cal-cont{
    padding: .5rem 0;
    width: 100%;
}
.md-date-pic .datepicker{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
}
.datepicker__calendar{
    top:2.5em !important;
    max-width: 100%;
    height: auto !important;
    z-index: 1025 !important;
}

@media only screen and (max-width: 573px){
    .datepicker__calendar__label,.datepicker__calendar__month__day{
        width: 1.4rem !important;
        height: 1.4rem !important;
        line-height: 1.4rem !important;
    }
}
.datepicker .datepicker__input {
    border: none !important;
    width: 100%;
    background-color: transparent !important;
    height: 100%;
}

.cal-icon{
    position: absolute;
    right: 15px;
    z-index: 0;
    top: 15px;
}
.datepicker__calendar__cancel{
    display: none;
}
/* material date-picker end */

/* color-picker start */
#changeme{
    font-size:30px;
    font-weight: bolder;
    cursor: pointer;
}
.cmyk{
    margin-left: 11px;
}
.cmyk div{
    height: 72px;
    width: 72px;
    line-height: 72px;
    text-align: center;
    float:left;
}
.cmyk span{
    font-weight: bolder;
    text-shadow: 1px 1px 2px #bbb;
}

#color-comparator button{
    height: 26px;
    width: 26px;
    display: block;
    float:left;
    border:none;
    margin:0;
    padding:0;
    border: 2px solid #888;
}

#color-comparator > input{
    height: 26px;
    width: 182px;
    display: block;
    float:left;
    border:none;
    margin:0;
    padding:0;
}

.array-colors-element{
    width: 100px;
    height: 25px;
    margin-bottom: 15px;
}
/* color-picker end */

/* components/forms/select start */

ng-select.ng-select > div {
    border: none;
}

ng-select.ng-select > div > div.single {
    background-color: #0073aa;
    color: #fff;
    padding: 8px 30px 8px 20px;
    border-radius: 4px;
    height: auto;
}

ng-select.ng-select > div.disabled > div.single {
    background-color: #aaa;
    color: #fff;
}

ng-select.ng-select > div > div.single > div.clear:hover, ng-select.ng-select > div > div.single > div.toggle:hover {
    background-color: transparent;
}

ng-select.ng-select > div > div.single > div.clear, ng-select.ng-select > div > div.single > div.toggle {
    color: #fff;
}

ng-select.ng-select > div > div.single > div.toggle {
    font-size: 9px;
}

ng-select.ng-select > div > div.single > div.clear, ng-select.ng-select > div > div.single > div.toggle {
    width: 0;
}

.ng-select select-dropdown .highlighted {
    background-color: #0073aa;
}

.famfamfam-flags {
    display: inline-block;
    margin-right: 6px;
    width: 16px;
}

ng-select.ng-select > div > div.multiple {
    border: 1px solid #aaa;
    padding: 6px 10px 6px 10px !important;
    border-radius: 4px;
}

ng-select.ng-select > div > div.multiple > div.option span.deselect-option {
    color: #fff !important;
    font-size: 10px !important;
    vertical-align: middle;
    font-weight: bold;
    margin-right: 2px;
}

ng-select.ng-select > div > div.multiple > div.option {
    background-color: #0073aa !important;
    border: 1px solid #0073aa !important;
    padding: 5px 15px !important;
    color: #fff !important;
    margin: 0 2px 2px !important;
}

ng-select.ng-select > div > div.single > div.placeholder {
    color: #fff;
}

ng-select.ng-select > div > div.single > div.clear {
    font-size: 10px;
    margin-right: 14px;
    font-weight: bold;
}

.tag-select .bootstrap tag {
    padding: 5px 10px !important;
    height: auto !important;
    z-index: 0;
}

.tag-select tag-input-form {
    padding: 6px 10px 6px 10px !important;
}

.tag-select .ng2-tag-input {
    border-bottom: 1px solid #aaa!important;
    border: 1px solid #aaa;
    padding: 5px !important;
    border-radius: 4px;
}

/* components/forms/select end */
