#site-builder-v3,
.site-builder-v3 {
  .helvetica-font {
    font-family: 'Helvetica', sans-serif;
  }
  .style-button {
    text-align: right;
  }

  .style-button .btn {
    padding: 0px 19px;
    font-family: 'Roboto';
    line-height: 260%;
    font-size: 12px;
  }

  .dummy-header {
    background-color: #F0F0F0;
    border: 1px solid #999999;
    position: relative;
  }

  ul {
    position: relative;
  }

  li {
    display: inline;
    margin: 0 6px 0 0;
  }

  .layout-form {
    max-width: 340px;
    margin-left: 0;
  }

  .button {
    background-color: #c4c4c4;
    cursor: pointer;
    min-height: 25px;
    text-align: center;
    line-height: 200%;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 50px;
    width: 100%;
  }

  .form-control {
    background-color: #FFFFFF !important;
    height: calc(2rem + 2px);
  }

  .blocks-popover-container .form-control {
    background-color: #FFFFFF !important;
    height: calc(1.5rem + 0px);
    padding: 1px;
  }

  .dots {
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 20px;
    padding: 0 9px;
    position: absolute;
    cursor: pointer;
    top: 50%;
    &.left {
      left: 55px;
      transform: translate(0, -53%);
      &.dots-center {
        left: 10px;
      }
    }
    &.right {
      right: 55px;
      transform: translate(0, -53%);
      &.dots-center {
        right: 15px;
      }
    }
  }

  .menu-center {
    text-align: center;
  }

  .menu-left {
    text-align: left;
  }

  .menu-right {
    text-align: right;
  }

  .transparency-bg {
    background-color: rgba(240, 240, 240, 1);
    border:none;
  }

  .solid-bg {
    background-color: #FFFFFF;
  }

  .logo-menu {
    position: relative;
    overflow: hidden;
    width: 65px;
  }

  .theme-popup-check {
    cursor: pointer;
  }

  .page-home {
    font-size: 20px;
    line-height: 24px;
    max-width: 255px;
    margin: 0 auto;
  }

  .page-page {
    font-size: 20px;
    line-height: 24px;
    max-width: 310px;
    margin: 0 auto;
  }

  .btn-dark {
    background: #4C7B81;
    border: 1px solid #CED4DA;
    font-weight: 700;
    width: 150px;
  }

  .btn-outline-dark {
    background: #FFFFFF;
    border: 1px solid #4C7B81;
    color: #3B585A;
    font-weight: 700;
    width: 150px;
  }

  .img-placeholder {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #F0F0F0;
    background-size: cover;
    text-align: center;
    position: relative;
    &.small-size {
      height: 120px;
      width: 120px;
      margin: 0 auto;
    }
    &.main {
      height: 170px;
    }
    &.doble, &.medio {
      height: 110px;
    }
    &.one-third {
      height: calc(170px / 3);
    }
    &.two-thirds {
      height: calc(170px * 2 / 3);
    }
    &.doble {
      max-width: 100%;
      margin: 0 auto;
    }
  }

  .side-text-panel-left {
    max-width: 155px;
    margin-left: auto;
  }

  .side-text-panel-right {
    max-width: 155px;
    margin-left: 0  ;
  }

  .color-text {
    color: #999999;
  }
  .color-subtitle {
    color: #666666;
  }
  .banners {
    position: relative;
    top: calc(50% - 25px);
    transform: translateY(-50%);

    &.banners-center {
      top: calc(50%);
    }
  }
  .font-8 {
    font-size: 8px;
  }
  .font-10 {
    font-size: 10px;
  }
  .font-12 {
    font-size: 12px;
  }
  .font-14 {
    font-size: 14px;
  }
  .font-13 {
    font-size: 13px;
  }
  .font-16 {
    font-size: 16px;
  }
  .font-26 {
    font-size: 26px;
  }
  .font-10 {
    font-size: 10px;
  }

  .w-150 {
    width: 150px;
  }

  .w-160 {
    width: 160px;
  }

  .w-300 {
    width: 300px;
  }

  .products-content {
    .row-2 {
      max-width: 100px;
      margin-left: auto;
      margin-right: auto;
    }
    .row-3 {
      max-width: 150px;
      margin-left: auto;
      margin-right: auto;
    }
    .row-4 {
      max-width: 210px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .category-container {
    max-width: 360;
    margin: 0 auto 40px;
  }

  .contact-panel {
    span.icon {
      position: absolute;
      left: 15px;
      height: 20px;
      width: 20px;
    }
  }

  .btn-contact {
    background-color: #0085FC;
    border-radius: 15px;
    line-height: 0;
    height: 20px;
    width: 100%;
  }

  textarea {
    resize: none;
    border: 1px solid #CED4DA
  }

  .input-min-height {
    height: 22px;
  }

  .w-120 {
    max-width: 120px;
  }

  .w-160 {
    max-width: 160px;
  }

  .carousel-caption {
    color: #000;
  }

  .carousel-control-next-icon {
    background-image: url('/assets/images/carrousel-arrow.svg');
  }

  .carousel-control-prev-icon {
    background-image: url('/assets/images/carrousel-arrow.svg');
    transform: rotate(180deg);
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .d-flex-box {
    width: 408px;
    padding-left: 12px;
    padding-right: 15px;
  }

  .d-flex-one {
    margin-right: 8px;
    flex: 1 1 auto;
  }
  .d-flex-two {
    width: 260px;
  }
  .d-flex-three {
    margin-left: 12px;
    width: 40px;
  }

  .header-button {
    font-size: 8px;
    line-height: 180%;
    max-width:60px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .carousel-style-text-background {
    .carousel-title {
      font-size: 20px;
      .custom-input-container {
        .content {
          padding: 6px;
        }
      }
    }
    .carousel-subtitle {
      font-size: 14px;
      .custom-input-container {
        .content {
          padding: 6px;
        }
      }
    }
  }
}

.slider-popover-container {
  max-width: 250px;
  width: 250px;

  .ngx-slider {
    .ngx-slider-pointer {
      background-color: #79C9D5 !important;
    }
  }

}
.slider-icon {
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }

  fa-icon {
    svg {
      filter: drop-shadow(0px 0px 1px #FFFF99)
    }
  }
}
.color-picker-icon,
.button-style-setter-icon {
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }

  fa-icon {
    svg {
      filter: drop-shadow(0px 0px 1px #fff)
    }
  }
}
.button-style-setter-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
}
.button-style-setter-popover-container {
  max-width: 350px;
  width: 350px;
}

#site-builder-v3 ckeditor, #site-builder-v3 .inner-html {
  ol {
    list-style-type: revert;
  }

  ul {
    list-style-type: revert;
    padding-left: revert;
  }

  li {
    display: revert;
  }
}
