/* components/basic/generic-class */
.md-tabs .nav-link.active {
    border-bottom: 4px solid #0073aa;
}

.md-tabs .nav-link{
    transition: all 0.4s ease-in-out;
    border-bottom: 4px solid transparent;
}
.tab-card .md-tabs{
    position: relative;
    top:-76px;
}
.tab-card .card-header{
    border-bottom: 0px;
}

.md-tabs .nav-link.active, .md-tabs .nav-link.active:focus, .md-tabs .nav-link.active:hover {
    border-bottom: 4px solid #0073aa;
}

/* components/basic/tabs */
/* below tab bootstrap start */
.below-tabs, .below-tabs .nav-tabs,.below-tabs .tab-content {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.below-tabs .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.below-tabs .nav-tabs {
    border-top: 1px solid #ddd;
    border-bottom: transparent;
}

.below-tabs .nav-tabs .nav-item.show .nav-link, .below-tabs .nav-tabs .nav-link.active {
    color: #464a4c;
    background-color: #fff;
    border-color: #fff #ddd #ddd;
    margin-top: -1px;
}
/* below tab bootstrap end */


/* tab material start */
.md-tabs .nav-link.active {
    border-bottom: 4px solid #0073aa;
}

.md-tabs .nav-link{
    transition: all 0.4s ease-in-out;
    border-bottom: 4px solid transparent;
}

.md-tabs .nav-link.active, .md-tabs .nav-link.active:focus, .md-tabs .nav-link.active:hover {
    border-bottom: 4px solid #0073aa;
}
/* tab material end */

/* below tab material start */
.md-tabs.md-below-tabs, .md-tabs.md-below-tabs .nav-tabs, .md-tabs.md-below-tabs .tab-content {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.md-tabs.md-below-tabs .nav-tabs, .md-tabs.md-below-tabs .nav-item:first-child {
    border-top: 1px solid #ddd;
    border-bottom: 0px solid transparent;
    margin-top: -1px;
}

.md-tabs.md-below-tabs .nav-link.active {
    border-top: 4px solid #0073aa;
}

.md-tabs.md-below-tabs .nav-link{
    transition: all 0.4s ease-in-out;
    border-top: 0px solid transparent;
}

.md-tabs.md-below-tabs .nav-link.active, .md-tabs.md-below-tabs .nav-link.active:focus, .md-tabs.md-below-tabs .nav-link.active:hover {
    border-top: 4px solid #0073aa;
    border-bottom: 0px solid transparent;
}

.md-tabs.md-below-tabs .nav-tabs .nav-link:hover {
    border-top: 4px solid #ddd;
    border-bottom: 0px solid transparent;
}

.md-tabs.md-below-tabs .nav-tabs .nav-link.active:hover {
    border-top: 4px solid #0073aa;
}
/* below tab material end */

/* left tab material start */
.md-tabs.md-left-tabs .nav-item{
    width: 100%;
}
.md-tabs.md-left-tabs .nav-item:first-child{
    border-bottom: 0px;
}
.md-tabs.md-left-tabs .nav-item .nav-link{
    border:0px solid transparent;
    padding: 10px 0 !important;
    margin: 10px 0;
}

.md-tabs.md-left-tabs .nav-link.active, .md-tabs.md-left-tabs .nav-link.active:focus, .md-tabs.md-left-tabs .nav-link.active:hover {
    border-left: 4px solid #0073aa;
    border-bottom: 0px solid transparent;
}

.md-tabs.md-left-tabs .nav-item .nav-link.active{
    border:0px solid transparent;
    border-left: 4px solid #0073aa;
}

.md-tabs.md-left-tabs .nav-tabs .nav-link.active:hover {
    border-left: 4px solid #0073aa;
}

.md-tabs.md-left-tabs .nav-tabs .nav-link:hover {
    border: 0px solid transparent;
    border-left: 4px solid #ddd;
}

.md-tabs.md-left-tabs .nav-tabs{
    border-bottom: 0px solid transparent;
    display: table-cell;
    min-width: 120px;
    vertical-align: top;
    width: 150px;
}

.md-tabs.md-left-tabs .tab-content{
    display: table-cell;
    top: 0;
    position: absolute;
    left: 150px;
}
@media only screen and ( max-width: 768px){
    .md-tabs.md-left-tabs .nav-tabs{
        width: 100%;
        display: block;
    }
    .md-tabs.md-left-tabs .tab-content{
        display: block;
        position: relative;
        left: 0;
    }
}
/* left tab material end */

/* right tab material start */
.md-tabs.md-right-tabs .nav-item{
    width: 100%;
}
.md-tabs.md-right-tabs .nav-item:first-child{
    border-bottom: 0px;
}
.md-tabs.md-right-tabs .nav-item .nav-link{
    border:0px solid transparent;
    padding: 10px 0 !important;
    margin: 10px 0;
}

.md-tabs.md-right-tabs .nav-link.active, .md-tabs.md-right-tabs .nav-link.active:focus, .md-tabs.md-right-tabs .nav-link.active:hover {
    border-right: 4px solid #0073aa;
    border-bottom: 0px solid transparent;
}

.md-tabs.md-right-tabs .nav-item .nav-link.active{
    border:0px solid transparent;
    border-right: 4px solid #0073aa;
}

.md-tabs.md-right-tabs .nav-tabs .nav-link.active:hover {
    border-right: 4px solid #0073aa;
}

.md-tabs.md-right-tabs .nav-tabs .nav-link:hover {
    border: 0px solid transparent;
    border-right: 4px solid #ddd;
}

.md-tabs.md-right-tabs .nav-tabs{
    border-bottom: 0px solid transparent;
    display: table-cell;
    min-width: 120px;
    vertical-align: top;
    width: 150px;
    float: right;
}

.md-tabs.md-right-tabs .tab-content{
    display: table-cell;
    top: 0;
    position: absolute;
    right: 150px;
}
@media only screen and ( max-width: 768px){
    .md-tabs.md-right-tabs .nav-tabs{
        width: 100%;
        display: block;
    }
    .md-tabs.md-right-tabs .tab-content{
        display: block;
        position: relative;
        left: 0;
    }
}
/* right tab material end */

/* image tab material start */
.md-tabs.md-image-tab .nav-link {
    position: relative;
}
.md-tabs.md-image-tab .nav-item, .md-tabs.md-image-tab .nav-link,.md-tabs.md-image-tab .nav-tabs{
    border: 0px solid transparent !important;
}
.md-tabs.md-image-tab img{
    width: 100px;
    border: 4px solid #0073aa;
}
.md-tabs.md-image-tab img + .quote {
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    bottom: 10px;
    right: 70px;
}
.md-tabs.md-image-tab .quote i{
    border-radius: 50%;
    padding: 3px 5px;
}
.md-tabs.md-image-tab .nav-link{
    opacity: 0.5;
}
.md-tabs.md-image-tab .nav-link.active{
    opacity: 1;
}
/* image tab material end */
