.facebook-color {
  color: #3b5998;
}

.instagram-color {
  color: #C13584;
}

.twitter-color {
  color: #00acee;
}