.sheyla-guide {
  font-family: 'Nunito', sans-serif !important;
  p,
  li {
    font-size: 14px;
  }

  img{
    max-width: 100%;
  }
}