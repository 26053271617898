body {
  background: #fff;
  font-family: 'Nunito', sans-serif;
}

.input-group+.messages {
  margin-top: -1.25em;
}

.input-group-append .btn-outline-secondary,
.input-group-prepend .btn-outline-secondary {
  border-color: #ced4da;
}

// Firefox dotted border on selected elements
// https://stackoverflow.com/questions/71074/how-to-remove-firefoxs-dotted-outline-on-buttons-as-well-as-links
:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

@media only screen and (max-width: 992px) {
  .main-body .page-wrapper {
    padding: 0;
  }
}

// Left menu dropdown
.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification a {
  padding: 0;
}

// List circle
.list-circle {
  list-style: circle;
  padding-left: 3rem;
  margin-bottom: 1rem;
}

.table-fixed{
  table-layout: fixed;
  td,
  th {
      word-wrap: break-word;
      white-space: normal;
  }
}

/* #toast-container > div {
  opacity:1;
}
*/

/* Submenu badges */
.pcoded .pcoded-navbar .pcoded-item li a .pcoded-mtext.has-badge {
  padding-right: 44px;
}

.pcoded .pcoded-navbar .pcoded-item li a .pcoded-badge {
  right: 5px;
}

/* Se evita el scroll al menu estableciendo el alto mínimo del contenido al disponible en la pantalla */
.pcoded-content {
  min-height: 100vh;
}

ul.styled {
  list-style-type: disc;
  padding-left: 2em;
}

// Tidio chat section (140px height)
.pcoded-inner-content {
  padding-bottom: 140px;
}

.cursor-pointer {
  cursor: pointer;
}

// Custom-file Input, se quita la línea doble inferior
.custom-file input[type="file"] {
  height: 100%;
}

// Custom tabs, inicio/suscripciones
.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
  color: #666;
  background-color: white;
  border: none;

  &.active {
    font-weight: bold;
    color: #79C9D5;
    background-color: white;
    border-bottom: 5px solid #E30613;
  }
}

.editable {
  border:1px solid transparent;
  &:hover {
    border:1px solid #cccccc
  }
}

.swal-primary {
  background-color: #79C9D5 !important;
  border-radius: 0% !important;
  border: 2px solid #79C9D5 !important;
}
.swal-secondary {
  background-color: white !important;
  border: 2px solid #79C9D5 !important;
  color: #79C9D5 !important;
  border-radius: 0% !important;

  &:hover {
    background-color: #79C9D5 !important;
    color: white !important;
  }
}

.no-shadow {
  box-shadow: none !important;
}

.form-group {
  .ng2-tag-input {
    border: 1px solid #ced4da !important;
  }
}
