.squeezebox .sb-item {
    /*overflow: hidden;*/
}

.squeezebox .sb-item-head a {
    cursor: pointer;
    display: block;
}

.squeezebox .sb-item .sb-item-head a .toggle-icon {
    display: block;
    float: right;
    height: 16px;
    position: relative;
    transform: rotate(45deg);
    transition: 0.3s transform;
    width: 16px;
}

.squeezebox .sb-item.is-collapsed .sb-item-head a .toggle-icon {
    transform: rotate(0);
}

.squeezebox .sb-item-head a .toggle-icon:before,
.squeezebox .sb-item-head a .toggle-icon:after {
    background: #000000;
    content: ' ';
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    width: 1px;
}

.squeezebox .sb-item-head a .toggle-icon:after {
    transform: rotate(90deg);
}

.squeezebox .sb-item .sb-item-body {
    height: 0;
    /*overflow: hidden;*/
    transition: 0.5s height;
}

.squeezebox .sb-item .sb-item-body .inner {
    padding: 10px 0 0 0;
}

.squeezebox .sb-item .sb-item-head a .toggle-icon {
    display: none;
}

.sb-item-head a:focus, .sb-item-head a:hover {
    color: #014c8c !important;
}
.sb-item.is-collapsed .sb-item-head >a{
    color: #000;
}
.sb-item .sb-item-head >a {
    color: #014c8c;
}
.sb-item.is-collapsed .sb-item-head {
    pointer-events: auto;
}
.sb-item .sb-item-head {
    pointer-events: none;
}
.scale-accordion .sb-item .sb-item-head {
    pointer-events: auto;
}
