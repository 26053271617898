.builder-faq-accordion {
  .card {
    position: relative;
    margin-left: 25px; // Para el circulo con número
    margin-bottom: 20px !important;

    &:first-of-type,
    &:last-of-type {
      margin-bottom: 20px;
    }

    .circle-count {
      position: absolute;
      top: 16px;
      left: -25px;

      width: 18px;
      height: 18px;
      line-height: 18px;
      border-radius: 50%;
      border: 1px solid #ced4da;

      font-size: 12px;
      font-weight: 400;
      color: #888888;
      background-color: #fff;
      text-align: center;
    }

    .minus-plus-trigger-container {
      position: absolute;
      right: 10px;
      top: 22px;

      .minus-plus-trigger {
        position: relative;
        width: 11px;
        height: 11px;

        &:before,
        &:after {
          content: "";
          position: absolute;
          background-color: #000;
          transition: transform 0.25s ease-out;
        }

        /* Vertical line */
        &:before {
          top: 0;
          left: 50%;
          width: 1px;
          height: 100%;
          margin-left: 0px;

          transform: rotate(0deg);
        }

        /* horizontal line */
        &:after {
          top: 50%;
          left: 0;
          width: 100%;
          height: 1px;
          margin-top: -1px;

          transform: rotate(0deg);
        }

        &.expanded {
          cursor: pointer;

          &:before{ transform: rotate(90deg); }
          &:after{ transform: rotate(180deg); }
        }
      }
    }

    .card-header {
      padding: 8px 30px 8px 12px;

      h5 {
        width: 100%;
        margin: 0;
        text-align: left;
      }

      .btn {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        text-align: left;
        color: #000;

        .text {
          color: #000;
        }
      }

      .btn-link:hover,
      .btn-link:focus {
        text-decoration: none;
        color: #000;
      }

      span {
        margin: 0;
        color: #000;
      }
    }
  }
}
