.rate-card {
  height: 100%;

  .provider-logo {
    text-align: center;

    img {
      width: 100%;
      height: auto;
      max-width: 200px;
    }
  }
}
