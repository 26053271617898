@charset "UTF-8";
@import url(~@ngx-share/button/styles/share-buttons.css);
/** Colors variables */
/** share button variables */
/** share buttons colors */
.sb-modern-dark .sb-wrapper {
  background-color: #2E4057;
  background-color: var(--button-color) !important;
  color: #fff;
  transition: box-shadow ease-in 150ms, transform ease-in 150ms;
  border-radius: 3px;
  box-shadow: 0 0.1em 0.5em rgba(165, 165, 165, 0.6); }
  .sb-modern-dark .sb-wrapper.sb-facebook {
    background-color: #4267B2; }
  .sb-modern-dark .sb-wrapper.sb-twitter {
    background-color: #00acee; }
  .sb-modern-dark .sb-wrapper.sb-google {
    background-color: #db4437; }
  .sb-modern-dark .sb-wrapper.sb-stumble {
    background-color: #eb4924; }
  .sb-modern-dark .sb-wrapper.sb-linkedin {
    background-color: #006fa6; }
  .sb-modern-dark .sb-wrapper.sb-pinterest {
    background-color: #bd081c; }
  .sb-modern-dark .sb-wrapper.sb-reddit {
    background-color: #ff4006; }
  .sb-modern-dark .sb-wrapper.sb-tumblr {
    background-color: #36465d; }
  .sb-modern-dark .sb-wrapper.sb-whatsapp {
    background-color: #25d366; }
  .sb-modern-dark .sb-wrapper.sb-messenger {
    background-color: #0080FF; }
  .sb-modern-dark .sb-wrapper.sb-telegram {
    background-color: #0088cc; }
  .sb-modern-dark .sb-wrapper.sb-xing {
    background-color: #006567; }
  .sb-modern-dark .sb-wrapper.sb-sms {
    background-color: #20c16c; }
  .sb-modern-dark .sb-wrapper.sb-email {
    background-color: #FF961C; }
  .sb-modern-dark .sb-wrapper.sb-vk {
    background-color: #4C75A3; }
  .sb-modern-dark .sb-wrapper.sb-copy {
    background-color: #607D8B; }
  .sb-modern-dark .sb-wrapper.sb-print {
    background-color: #765AA2; }
  .sb-modern-dark .sb-wrapper.sb-expand {
    background-color: #FF6651; }
  .sb-modern-dark .sb-wrapper:active {
    box-shadow: 0 0.4em 1em rgba(165, 165, 165, 0.9); }
  .sb-modern-dark .sb-wrapper .sb-text,
  .sb-modern-dark .sb-wrapper .sb-count {
    background: rgba(0, 0, 0, 0.1); }
  .sb-modern-dark .sb-wrapper.sb-show-text.sb-show-count .sb-text {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .sb-modern-dark .sb-wrapper.sb-show-text.sb-show-count .sb-count {
    background: none; }
  .sb-modern-dark .sb-wrapper.sb-show-text, .sb-modern-dark .sb-wrapper.sb-show-count {
    padding: 0; }
    .sb-modern-dark .sb-wrapper.sb-show-text .sb-icon, .sb-modern-dark .sb-wrapper.sb-show-count .sb-icon {
      box-shadow: 1px 0 1px -1px rgba(0, 0, 0, 0.3); }
    .sb-modern-dark .sb-wrapper.sb-show-text .sb-text, .sb-modern-dark .sb-wrapper.sb-show-text .sb-count, .sb-modern-dark .sb-wrapper.sb-show-count .sb-text, .sb-modern-dark .sb-wrapper.sb-show-count .sb-count {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px; }

#toasty .toast {
  width: auto;
  height: 55px; }

#toasty .toast .close-button {
  top: calc(50% - 12px);
  opacity: 1; }

#toasty .toast .toast-text {
  padding: 5px 35px 5px 60px; }

/* components/advance/notify*/
.small-icon .icon {
  width: auto !important;
  height: auto !important; }

.no-icon .icon {
  display: none; }

.simple-notification-wrapper {
  z-index: 10001 !important; }

.squeezebox .sb-item {
  /*overflow: hidden;*/ }

.squeezebox .sb-item-head a {
  cursor: pointer;
  display: block; }

.squeezebox .sb-item .sb-item-head a .toggle-icon {
  display: block;
  float: right;
  height: 16px;
  position: relative;
  transform: rotate(45deg);
  transition: 0.3s transform;
  width: 16px; }

.squeezebox .sb-item.is-collapsed .sb-item-head a .toggle-icon {
  transform: rotate(0); }

.squeezebox .sb-item-head a .toggle-icon:before,
.squeezebox .sb-item-head a .toggle-icon:after {
  background: #000000;
  content: ' ';
  height: 100%;
  left: 50%;
  position: absolute;
  top: 0;
  width: 1px; }

.squeezebox .sb-item-head a .toggle-icon:after {
  transform: rotate(90deg); }

.squeezebox .sb-item .sb-item-body {
  height: 0;
  /*overflow: hidden;*/
  transition: 0.5s height; }

.squeezebox .sb-item .sb-item-body .inner {
  padding: 10px 0 0 0; }

.squeezebox .sb-item .sb-item-head a .toggle-icon {
  display: none; }

.sb-item-head a:focus, .sb-item-head a:hover {
  color: #014c8c !important; }

.sb-item.is-collapsed .sb-item-head > a {
  color: #000; }

.sb-item .sb-item-head > a {
  color: #014c8c; }

.sb-item.is-collapsed .sb-item-head {
  pointer-events: auto; }

.sb-item .sb-item-head {
  pointer-events: none; }

.scale-accordion .sb-item .sb-item-head {
  pointer-events: auto; }

/* components/basic/generic-class */
.md-tabs .nav-link.active {
  border-bottom: 4px solid #0073aa; }

.md-tabs .nav-link {
  transition: all 0.4s ease-in-out;
  border-bottom: 4px solid transparent; }

.tab-card .md-tabs {
  position: relative;
  top: -76px; }

.tab-card .card-header {
  border-bottom: 0px; }

.md-tabs .nav-link.active, .md-tabs .nav-link.active:focus, .md-tabs .nav-link.active:hover {
  border-bottom: 4px solid #0073aa; }

/* components/basic/tabs */
/* below tab bootstrap start */
.below-tabs, .below-tabs .nav-tabs, .below-tabs .tab-content {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.below-tabs .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.below-tabs .nav-tabs {
  border-top: 1px solid #ddd;
  border-bottom: transparent; }

.below-tabs .nav-tabs .nav-item.show .nav-link, .below-tabs .nav-tabs .nav-link.active {
  color: #464a4c;
  background-color: #fff;
  border-color: #fff #ddd #ddd;
  margin-top: -1px; }

/* below tab bootstrap end */
/* tab material start */
.md-tabs .nav-link.active {
  border-bottom: 4px solid #0073aa; }

.md-tabs .nav-link {
  transition: all 0.4s ease-in-out;
  border-bottom: 4px solid transparent; }

.md-tabs .nav-link.active, .md-tabs .nav-link.active:focus, .md-tabs .nav-link.active:hover {
  border-bottom: 4px solid #0073aa; }

/* tab material end */
/* below tab material start */
.md-tabs.md-below-tabs, .md-tabs.md-below-tabs .nav-tabs, .md-tabs.md-below-tabs .tab-content {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.md-tabs.md-below-tabs .nav-tabs, .md-tabs.md-below-tabs .nav-item:first-child {
  border-top: 1px solid #ddd;
  border-bottom: 0px solid transparent;
  margin-top: -1px; }

.md-tabs.md-below-tabs .nav-link.active {
  border-top: 4px solid #0073aa; }

.md-tabs.md-below-tabs .nav-link {
  transition: all 0.4s ease-in-out;
  border-top: 0px solid transparent; }

.md-tabs.md-below-tabs .nav-link.active, .md-tabs.md-below-tabs .nav-link.active:focus, .md-tabs.md-below-tabs .nav-link.active:hover {
  border-top: 4px solid #0073aa;
  border-bottom: 0px solid transparent; }

.md-tabs.md-below-tabs .nav-tabs .nav-link:hover {
  border-top: 4px solid #ddd;
  border-bottom: 0px solid transparent; }

.md-tabs.md-below-tabs .nav-tabs .nav-link.active:hover {
  border-top: 4px solid #0073aa; }

/* below tab material end */
/* left tab material start */
.md-tabs.md-left-tabs .nav-item {
  width: 100%; }

.md-tabs.md-left-tabs .nav-item:first-child {
  border-bottom: 0px; }

.md-tabs.md-left-tabs .nav-item .nav-link {
  border: 0px solid transparent;
  padding: 10px 0 !important;
  margin: 10px 0; }

.md-tabs.md-left-tabs .nav-link.active, .md-tabs.md-left-tabs .nav-link.active:focus, .md-tabs.md-left-tabs .nav-link.active:hover {
  border-left: 4px solid #0073aa;
  border-bottom: 0px solid transparent; }

.md-tabs.md-left-tabs .nav-item .nav-link.active {
  border: 0px solid transparent;
  border-left: 4px solid #0073aa; }

.md-tabs.md-left-tabs .nav-tabs .nav-link.active:hover {
  border-left: 4px solid #0073aa; }

.md-tabs.md-left-tabs .nav-tabs .nav-link:hover {
  border: 0px solid transparent;
  border-left: 4px solid #ddd; }

.md-tabs.md-left-tabs .nav-tabs {
  border-bottom: 0px solid transparent;
  display: table-cell;
  min-width: 120px;
  vertical-align: top;
  width: 150px; }

.md-tabs.md-left-tabs .tab-content {
  display: table-cell;
  top: 0;
  position: absolute;
  left: 150px; }

@media only screen and (max-width: 768px) {
  .md-tabs.md-left-tabs .nav-tabs {
    width: 100%;
    display: block; }
  .md-tabs.md-left-tabs .tab-content {
    display: block;
    position: relative;
    left: 0; } }

/* left tab material end */
/* right tab material start */
.md-tabs.md-right-tabs .nav-item {
  width: 100%; }

.md-tabs.md-right-tabs .nav-item:first-child {
  border-bottom: 0px; }

.md-tabs.md-right-tabs .nav-item .nav-link {
  border: 0px solid transparent;
  padding: 10px 0 !important;
  margin: 10px 0; }

.md-tabs.md-right-tabs .nav-link.active, .md-tabs.md-right-tabs .nav-link.active:focus, .md-tabs.md-right-tabs .nav-link.active:hover {
  border-right: 4px solid #0073aa;
  border-bottom: 0px solid transparent; }

.md-tabs.md-right-tabs .nav-item .nav-link.active {
  border: 0px solid transparent;
  border-right: 4px solid #0073aa; }

.md-tabs.md-right-tabs .nav-tabs .nav-link.active:hover {
  border-right: 4px solid #0073aa; }

.md-tabs.md-right-tabs .nav-tabs .nav-link:hover {
  border: 0px solid transparent;
  border-right: 4px solid #ddd; }

.md-tabs.md-right-tabs .nav-tabs {
  border-bottom: 0px solid transparent;
  display: table-cell;
  min-width: 120px;
  vertical-align: top;
  width: 150px;
  float: right; }

.md-tabs.md-right-tabs .tab-content {
  display: table-cell;
  top: 0;
  position: absolute;
  right: 150px; }

@media only screen and (max-width: 768px) {
  .md-tabs.md-right-tabs .nav-tabs {
    width: 100%;
    display: block; }
  .md-tabs.md-right-tabs .tab-content {
    display: block;
    position: relative;
    left: 0; } }

/* right tab material end */
/* image tab material start */
.md-tabs.md-image-tab .nav-link {
  position: relative; }

.md-tabs.md-image-tab .nav-item, .md-tabs.md-image-tab .nav-link, .md-tabs.md-image-tab .nav-tabs {
  border: 0px solid transparent !important; }

.md-tabs.md-image-tab img {
  width: 100px;
  border: 4px solid #0073aa; }

.md-tabs.md-image-tab img + .quote {
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  bottom: 10px;
  right: 70px; }

.md-tabs.md-image-tab .quote i {
  border-radius: 50%;
  padding: 3px 5px; }

.md-tabs.md-image-tab .nav-link {
  opacity: 0.5; }

.md-tabs.md-image-tab .nav-link.active {
  opacity: 1; }

/* image tab material end */
/* components/forms/advance-elements */
.switch {
  vertical-align: middle !important; }

.ng2-tag-input {
  border-bottom: 1px solid #0073aa !important;
  border: 1px solid #0073aa;
  padding: 5px !important; }

.tag__text {
  line-height: 2;
  font-size: 11px;
  font-weight: 600; }

delete-icon svg {
  height: 13px !important;
  line-height: 1;
  vertical-align: top !important;
  margin-top: 5px; }

.ng2-tags-container tag {
  padding: 0 5px !important;
  height: 22px !important; }

delete-icon:hover {
  transform: none !important; }

.ng2-tag-input__text-input {
  height: auto !important; }

.bootstrap tag {
  background: #0073aa !important; }

/* components/forms/advance-validations */
.tooltip-form {
  position: relative; }

.tooltip-error {
  position: absolute;
  right: 30px;
  top: 7px; }

/* components/forms/picker */
/* bootstrap date-picker start */
.custom-range {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem; }

.custom-range.focused {
  background-color: #e6e6e6; }

.custom-range.range, .custom-range:hover {
  background-color: #0275d8;
  color: white; }

.faded {
  opacity: 0.5; }

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  border-radius: 0.25rem;
  display: inline-block;
  width: 2rem; }

.custom-day:hover, .custom-day.focused {
  background-color: #e6e6e6; }

.weekend {
  background-color: #999;
  border-radius: 1rem;
  color: white; }

.hidden {
  display: none; }

@media (min-width: 576px) {
  .form-inline .form-group {
    display: block;
    flex: 240px; } }

/* bootstrap date-picker end */
/* material date-picker start */
.md-date-pic {
  position: relative;
  width: 20.5em;
  max-width: 100%;
  border-bottom: 2px solid #ddd; }

.md-date-pic .cal-cont {
  padding: .5rem 0;
  width: 100%; }

.md-date-pic .datepicker {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.datepicker__calendar {
  top: 2.5em !important;
  max-width: 100%;
  height: auto !important;
  z-index: 1025 !important; }

@media only screen and (max-width: 573px) {
  .datepicker__calendar__label, .datepicker__calendar__month__day {
    width: 1.4rem !important;
    height: 1.4rem !important;
    line-height: 1.4rem !important; } }

.datepicker .datepicker__input {
  border: none !important;
  width: 100%;
  background-color: transparent !important;
  height: 100%; }

.cal-icon {
  position: absolute;
  right: 15px;
  z-index: 0;
  top: 15px; }

.datepicker__calendar__cancel {
  display: none; }

/* material date-picker end */
/* color-picker start */
#changeme {
  font-size: 30px;
  font-weight: bolder;
  cursor: pointer; }

.cmyk {
  margin-left: 11px; }

.cmyk div {
  height: 72px;
  width: 72px;
  line-height: 72px;
  text-align: center;
  float: left; }

.cmyk span {
  font-weight: bolder;
  text-shadow: 1px 1px 2px #bbb; }

#color-comparator button {
  height: 26px;
  width: 26px;
  display: block;
  float: left;
  border: none;
  margin: 0;
  padding: 0;
  border: 2px solid #888; }

#color-comparator > input {
  height: 26px;
  width: 182px;
  display: block;
  float: left;
  border: none;
  margin: 0;
  padding: 0; }

.array-colors-element {
  width: 100px;
  height: 25px;
  margin-bottom: 15px; }

/* color-picker end */
/* components/forms/select start */
ng-select.ng-select > div {
  border: none; }

ng-select.ng-select > div > div.single {
  background-color: #0073aa;
  color: #fff;
  padding: 8px 30px 8px 20px;
  border-radius: 4px;
  height: auto; }

ng-select.ng-select > div.disabled > div.single {
  background-color: #aaa;
  color: #fff; }

ng-select.ng-select > div > div.single > div.clear:hover, ng-select.ng-select > div > div.single > div.toggle:hover {
  background-color: transparent; }

ng-select.ng-select > div > div.single > div.clear, ng-select.ng-select > div > div.single > div.toggle {
  color: #fff; }

ng-select.ng-select > div > div.single > div.toggle {
  font-size: 9px; }

ng-select.ng-select > div > div.single > div.clear, ng-select.ng-select > div > div.single > div.toggle {
  width: 0; }

.ng-select select-dropdown .highlighted {
  background-color: #0073aa; }

.famfamfam-flags {
  display: inline-block;
  margin-right: 6px;
  width: 16px; }

ng-select.ng-select > div > div.multiple {
  border: 1px solid #aaa;
  padding: 6px 10px 6px 10px !important;
  border-radius: 4px; }

ng-select.ng-select > div > div.multiple > div.option span.deselect-option {
  color: #fff !important;
  font-size: 10px !important;
  vertical-align: middle;
  font-weight: bold;
  margin-right: 2px; }

ng-select.ng-select > div > div.multiple > div.option {
  background-color: #0073aa !important;
  border: 1px solid #0073aa !important;
  padding: 5px 15px !important;
  color: #fff !important;
  margin: 0 2px 2px !important; }

ng-select.ng-select > div > div.single > div.placeholder {
  color: #fff; }

ng-select.ng-select > div > div.single > div.clear {
  font-size: 10px;
  margin-right: 14px;
  font-weight: bold; }

.tag-select .bootstrap tag {
  padding: 5px 10px !important;
  height: auto !important;
  z-index: 0; }

.tag-select tag-input-form {
  padding: 6px 10px 6px 10px !important; }

.tag-select .ng2-tag-input {
  border-bottom: 1px solid #aaa !important;
  border: 1px solid #aaa;
  padding: 5px !important;
  border-radius: 4px; }

/* components/forms/select end */
/* components/tables/data-table start */
ngx-datatable.data-table {
  border: 1px solid #eceeef; }

.data-table datatable-header {
  border-bottom: 2px solid #ccc; }

.data-table datatable-footer {
  border-top: 2px solid #ccc;
  padding: 0 0.75rem;
  background-color: #eceeef;
  box-shadow: inset 0 2px 4px -2px rgba(0, 0, 0, 0.15); }

.data-table .empty-row {
  padding: 0.75rem;
  text-align: center; }

.data-table .datatable-row-wrapper:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.data-table datatable-body-cell {
  padding: 0.75rem; }

.data-table datatable-body-cell + datatable-body-cell {
  border-left: 1px solid #eceeef; }

.data-table datatable-header-cell .datatable-header-cell-label {
  padding: .75rem 30px .75rem .75rem; }

.data-table datatable-header-cell + datatable-header-cell {
  border-left: 1px solid #eceeef; }

.data-table datatable-header-cell.sortable:before, .data-table datatable-header-cell.sortable:after {
  font-family: "IcoFont" !important;
  position: absolute;
  top: 10px;
  color: #ccc;
  font-size: 20px; }

.data-table datatable-header-cell.sortable:after {
  content: "\eb53";
  right: 10px; }

.data-table datatable-header-cell.sortable:before {
  content: "\eb56";
  right: 15px; }

.data-table datatable-header-cell.sortable.sort-active.sort-desc:after {
  color: #666; }

.data-table datatable-header-cell.sortable.sort-active.sort-asc:before {
  color: #666; }

.data-table.scroll .datatable-body, .data-table.fullscreen .datatable-body {
  height: 500px !important; }

.data-table datatable-scroller {
  width: auto !important; }

.datatable-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 16px; }

.datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem; }

.datatable-footer .datatable-pager {
  margin: 0 10px; }

.datatable-footer .datatable-pager li {
  vertical-align: middle; }

.datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: transparent !important; }

.datatable-footer .datatable-pager li.active a {
  background-color: #0073AA;
  color: #fff; }

.datatable-footer .datatable-pager a {
  padding: 0 6px;
  border-radius: 3px;
  margin: 6px 3px;
  text-align: center;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
  vertical-align: bottom; }

.datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.75);
  background-color: rgba(158, 158, 158, 0.2); }

.datatable-footer .datatable-pager .datatable-icon-left,
.datatable-footer .datatable-pager .datatable-icon-skip,
.datatable-footer .datatable-pager .datatable-icon-right,
.datatable-footer .datatable-pager .datatable-icon-prev {
  font-size: 20px;
  line-height: 20px;
  padding: 0 3px; }

.datatable-icon-right:before {
  content: "\eb27";
  font-family: 'icofont';
  font-style: normal; }

.datatable-icon-skip:before {
  content: "\eb3a";
  font-family: 'icofont';
  font-style: normal; }

.datatable-icon-left:before {
  content: "\eb26";
  font-family: 'icofont';
  font-style: normal; }

.datatable-icon-prev:before {
  content: "\eb39";
  font-family: 'icofont';
  font-style: normal; }

.dt-desc th, .dt-desc td {
  padding: .75rem; }

.dt-desc {
  padding: 0.45rem 50px; }

.dt-desc table {
  min-width: 400px; }

.datatable-row-detail {
  background-color: #f9f9f9;
  box-shadow: inset 0px 2px 9px -6px rgba(0, 0, 0, 0.68); }

.expandable datatable-body-cell + datatable-body-cell, .expandable datatable-header-cell + datatable-header-cell {
  width: calc(calc(100% - 50px) / 5) !important; }

.expandable .datatable-body-row, .expandable .datatable-header-inner, .datatable-row-center, .datatable-scroll {
  width: 100% !important; }

.data-table.expandable datatable-scroller {
  width: 100% !important; }

.ngx-datatable.data-table.single-selection .datatable-body-row.active:hover .datatable-row-group,
.ngx-datatable.data-table.multi-selection .datatable-body-row.active:hover .datatable-row-group,
.ngx-datatable.data-table.single-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.data-table.multi-selection .datatable-body-row.active .datatable-row-group,
.selection-cell .datatable-body-cell.active {
  background: #0073aa !important;
  color: #FFF !important; }

.selected-column label {
  margin-bottom: 5px; }

.dt-chk label {
  margin-bottom: 0; }

.dt-chk .datatable-body-cell {
  line-height: 1 !important; }

.full-data-search {
  margin-left: 0.5em;
  display: inline-block;
  width: auto !important; }

.full-data-show-entry {
  width: 75px;
  display: inline-block; }

.full-data-table .glyphicon {
  font-family: IcoFont;
  position: absolute;
  right: 14px;
  font-size: 20px;
  top: 9px; }

.full-data-table .glyphicon.glyphicon-triangle-bottom:before {
  content: "\eb53"; }

.full-data-table .glyphicon.glyphicon-triangle-top:before {
  content: "\eb56"; }

.full-data-table th {
  position: relative; }

/* components/tables/data-table end */
/* Helper classes */
@keyframes cd-scroll-right {
  0%,
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0); }
  50% {
    -webkit-transform: translateX(3px);
    -moz-transform: translateX(3px);
    -ms-transform: translateX(3px);
    -o-transform: translateX(3px);
    transform: translateX(3px); } }

.table-scroll-container {
  position: relative;
  max-width: 100%; }
  .table-scroll-container::before {
    display: none;
    content: '';
    position: absolute;
    top: 0;
    right: -1px;
    height: 100%;
    width: 35px;
    background: linear-gradient(to left, #fff 10%, rgba(255, 255, 255, 0));
    z-index: 5;
    pointer-events: none; }
  .table-scroll-container .scroll-container {
    overflow-y: auto; }
  .table-scroll-container .scroll-right {
    display: none;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -8px;
    background: url(/assets/images/small-arrow.svg?1400497079) no-repeat center center;
    z-index: 6;
    animation: cd-scroll-right 1.5s infinite; }

@media only screen and (max-width: 768px) {
  .table-scroll-container::before,
  .table-scroll-container .scroll-right {
    display: block; } }

/* End helper classes */
/* widget */
.widget.peity-chart .peity {
  width: 100%;
  height: 75px; }

.widget.card-body .new-task .fade-in-primary .cr {
  margin-top: 12px; }

.ui.table td.active, .ui.table tr.active {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.87) inset;
  background: #E0E0E0 !important;
  color: #fff !important; }

.widget .calendar:focus,
.widget .calendar:active {
  outline: none !important; }

.widget .table td.active,
.widget .table tr.active {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.87) inset;
  background: #0073aa !important;
  color: #fff !important;
  /*border-radius: 5px;*/ }

.widget td.link.today,
.widget td.link.active.today {
  background-color: #4680ff  !important;
  color: #fff !important;
  text-align: center;
  /*border-radius: 5px;*/ }

.widget .table th {
  width: calc(100% / 14);
  border: none !important;
  text-align: center; }

.widget .table thead tr + tr {
  border-top: 1px solid #ededed;
  border-bottom: 3px solid #ededed; }

.widget .table td {
  border-top: 1px solid transparent;
  text-align: center;
  margin: 10px 5px;
  padding: 6px;
  width: 35px;
  height: 35px;
  display: inline-block;
  border-radius: 50%; }

.widget .table td.link {
  cursor: pointer; }

.widget .day {
  color: #757575; }

.widget .table tr {
  justify-content: space-evenly;
  display: flex; }

.widget .table.month td,
.widget .table.year td {
  padding: 12px;
  width: 50px;
  height: 50px; }

.widget .table.year td {
  padding: 13px 10px; }

.widget .table.day td:first-child,
.widget .table.day td:last-child,
.widget .table.day th:first-child,
.widget .table.day th:last-child {
  color: #ff4040; }

.widget .table thead tr:first-child th {
  color: #000;
  width: 100%;
  font-size: 25px; }

.widget .table thead tr:first-child {
  background-color: #fafafa; }

.widget .table thead tr:first-child th {
  color: #757575 !important; }

.widget .table td.adjacent.disabled {
  opacity: 0.3; }

.widget table tbody tr:last-child td.today {
  width: 20%;
  border-radius: 6px;
  background-color: #0073aa !important; }

.input-color-picker {
  position: relative; }
  .input-color-picker label {
    position: relative;
    height: 100%;
    cursor: pointer; }
  .input-color-picker .color {
    width: 34px;
    height: 34px;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: inline-block;
    vertical-align: middle;
    outline: none;
    border: 1px solid transparent; }
  .input-color-picker input.selector {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 34px;
    height: 34px;
    outline: none;
    opacity: 0; }

.step-container {
  position: relative;
  margin: 0 auto;
  text-align: center;
  cursor: pointer; }
  .step-container .step {
    position: absolute;
    top: -8px;
    left: -6px;
    background: #E30613;
    border-radius: 0.8em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 1.6em;
    margin-right: 5px;
    text-align: center;
    width: 1.6em; }
  .step-container.circle {
    display: flex;
    border-radius: 35%;
    width: 87px;
    height: 85px;
    padding: 14px;
    margin-bottom: 8px;
    background: #fff;
    border: 2px solid #888;
    color: #666;
    text-align: center; }
    .step-container.circle img {
      max-width: 100%;
      width: 100%;
      height: auto;
      -webkit-filter: grayscale(100%);
      /* Safari 6.0 - 9.0 */
      filter: grayscale(100%); }
  .step-container.selected {
    border-width: 5px; }
    .step-container.selected .step {
      top: -11px;
      left: -9px; }
  .step-container.completed {
    border-color: #79C9D5; }
    .step-container.completed .step {
      background: #79C9D5; }
    .step-container.completed img {
      -webkit-filter: none;
      filter: none; }

.ng2-tag-input.foundation-theme {
  display: block;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  cursor: text;
  border-bottom: 1px solid #27ae60; }
  .ng2-tag-input.foundation-theme:focus {
    outline: 0; }
  .ng2-tag-input.foundation-theme.ng2-tag-input--dropping {
    opacity: 0.7; }
  .ng2-tag-input.foundation-theme.ng2-tag-input--loading {
    border: none; }
  .ng2-tag-input.foundation-theme.ng2-tag-input--disabled {
    opacity: 0.5;
    cursor: not-allowed; }
  .ng2-tag-input.foundation-theme .ng2-tags-container {
    flex-wrap: wrap;
    display: flex; }

.ng2-tag-input.foundation-theme tag {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #fff;
  border-radius: 2px;
  background: #27ae60;
  user-select: none;
  overflow: hidden;
  outline: 0;
  cursor: pointer;
  position: relative; }
  .ng2-tag-input.foundation-theme tag:not(.readonly):not(.tag--editing):focus {
    background: #1e8449; }
  .ng2-tag-input.foundation-theme tag:not(.readonly):not(.tag--editing):active {
    background: #1e8449; }
  .ng2-tag-input.foundation-theme tag:not(:focus):not(.tag--editing):not(:active):not(.readonly):hover {
    background: #1e8449;
    color: #fff; }
  .ng2-tag-input.foundation-theme tag.readonly {
    cursor: default; }
  .ng2-tag-input.foundation-theme tag.readonly:focus, .ng2-tag-input.foundation-theme tag:focus {
    outline: 0; }
  .ng2-tag-input.foundation-theme tag.tag--editing {
    cursor: text; }

.ng2-tag-input.foundation-theme tag delete-icon {
  transition: all 0.35s;
  text-align: right; }
  .ng2-tag-input.foundation-theme tag delete-icon path {
    fill: #fff; }
  .ng2-tag-input.foundation-theme tag delete-icon svg {
    vertical-align: bottom;
    height: 34px; }

.wrapper-card {
  display: flex;
  flex-wrap: wrap;
  margin-top: 76px; }

.subscription-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 100%;
  max-width: 260px;
  margin: 0;
  padding: 34px 7px 34px 16px;
  background: #FFFFFF;
  border: 1px solid rgba(33, 37, 41, 0.2);
  box-sizing: border-box;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
  text-align: center;
  transition: all 0.5s ease-in-out; }
  .subscription-card.popular {
    position: relative;
    padding: 34px 7px;
    border: 4px solid #79C9D5;
    border-radius: 10px;
    margin-top: -24px; }
  .subscription-card.escale {
    padding: 34px 7px; }
  .subscription-card:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); }

.card-ribbon {
  position: absolute;
  overflow: hidden;
  top: -10px;
  left: -10px;
  width: 114px;
  height: 112px; }
  .card-ribbon span {
    position: absolute;
    display: block;
    width: 160px;
    padding: 10px 0;
    background-color: #3498db;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    text-align: center;
    left: -35px;
    top: 25px;
    transform: rotate(-45deg); }
  .card-ribbon::before, .card-ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
    border-top-color: transparent;
    border-left-color: transparent; }
  .card-ribbon::before {
    top: 0;
    right: 0; }
  .card-ribbon::after {
    bottom: 0;
    left: 0; }

.card-title h3 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #E30613; }

.card-title h4 {
  color: rgba(0, 0, 0, 0.6); }

.card-price {
  margin: 0; }
  .card-price h1 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: #666666; }
    .card-price h1 .currency,
    .card-price h1 .recurrency,
    .card-price h1 .taxes {
      font-size: 14px; }
    .card-price h1 .info {
      font-size: 16px; }

.card-description {
  flex: 1;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px; }
  .card-description ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0; }
  .card-description li {
    color: #000;
    margin: 0 0 16px;
    text-align: left; }
    .card-description li::before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f00c";
      padding: 0 10px 0 0;
      color: #79C9D5; }
    .card-description li.no::before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f00d";
      padding: 0 10px 0 0;
      color: rgba(255, 0, 0, 0.25); }

.card-action {
  margin: 25px; }
  .card-action button,
  .card-action .btn {
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 12px 0;
    border: 1px solid #5DA3B1;
    background: #5DA3B1;
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease-in-out; }
    .card-action button:hover,
    .card-action .btn:hover {
      background-color: #5DA3B1;
      box-shadow: 0 2px 4px #376871;
      font-size: 20px; }

.section-title {
  font-size: 1.3em; }

.reference {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  text-align: left;
  margin-top: 24px; }

@media screen and (max-width: 1000px) {
  .wrapper-card {
    display: flex;
    flex-wrap: wrap;
    max-width: auto;
    width: 90%; }
  .subscription-card,
  .subscription-card.popular {
    margin: 0;
    margin-bottom: 30px; } }

.escale-image {
  position: relative;
  margin: 8px auto;
  text-align: center; }
  .escale-image img {
    width: 160px;
    max-width: 100%;
    height: auto; }

.escale-trademark {
  margin: 0 auto 4px auto;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: #666666; }

.tailormade-solutions {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #666666; }

.plan-payment-section-container {
  margin-top: 70px; }
  .plan-payment-section-container .card .card-header {
    padding: 26px 28px; }
    .plan-payment-section-container .card .card-header h1 {
      font-family: 'Roboto', sans-serif;
      font-size: 18px;
      font-weight: normal;
      margin: 0;
      color: #000; }
  .plan-payment-section-container .card .card-body {
    padding: 26px 28px; }

#progressbar {
  margin-bottom: 30px;
  overflow: visible;
  color: lightgrey;
  padding: 0;
  width: 100%;
  margin: 0 auto;
  max-width: 360px; }

#progressbar .active {
  color: #495057; }

#progressbar .active.dot:before {
  color: #ffffff; }

#progressbar li {
  color: #495057;
  text-align: center;
  font-weight: normal;
  list-style-type: none;
  font-size: 12px;
  width: 32.3%;
  margin: 0;
  float: left;
  position: relative; }

#progressbar .dot {
  margin: 0;
  outline: none; }

#progressbar .dot-certified {
  text-align: left; }

#progressbar .dot-certified:before {
  text-align: center;
  margin-left: 0;
  content: "1"; }

#progressbar .dot-seller:before {
  content: "2"; }

#progressbar .dot-buys {
  text-align: right; }

#progressbar .dot-buys:before {
  margin-right: 0;
  text-align: center;
  content: "3"; }

#progressbar .dot-buys.two:before {
  content: "2"; }

#progressbar .three:before {
  content: "3"; }

#progressbar .four:before {
  content: "4"; }

#progressbar li span {
  max-width: 60px;
  width: 100%;
  display: block;
  text-align: center; }

#progressbar li:nth-child(1) span {
  margin-left: -15px; }

#progressbar li:not(:first-child):not(:last-child) span {
  margin: 0 auto; }

#progressbar li:last-child span {
  margin-left: auto;
  margin-right: -15px; }

#progressbar li:before {
  border: 1px solid #ced4da;
  width: 30px;
  height: 30px;
  line-height: 26px;
  display: block;
  font-size: 14px;
  color: #999999;
  background: #ffffff;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
  position: relative;
  z-index: 1; }

#progressbar li:after {
  content: "";
  border: 1px solid #ced4da;
  width: 100%;
  height: 2px;
  background: #ffffff;
  position: absolute;
  left: 0;
  top: 15px;
  z-index: 0; }

#progressbar li.active:before {
  background: #E30613; }

@media (max-width: 767px) {
  #progressbar {
    padding-left: 0; }
  #progressbar .dot {
    font-size: 13px; }
  #progressbar .dot-certified span {
    text-align: center;
    margin-left: 0; }
  #progressbar .dot-buys span {
    text-align: center;
    margin-right: 0; } }

#site-builder-v3 .helvetica-font,
.site-builder-v3 .helvetica-font {
  font-family: 'Helvetica', sans-serif; }

#site-builder-v3 .style-button,
.site-builder-v3 .style-button {
  text-align: right; }

#site-builder-v3 .style-button .btn,
.site-builder-v3 .style-button .btn {
  padding: 0px 19px;
  font-family: 'Roboto';
  line-height: 260%;
  font-size: 12px; }

#site-builder-v3 .dummy-header,
.site-builder-v3 .dummy-header {
  background-color: #F0F0F0;
  border: 1px solid #999999;
  position: relative; }

#site-builder-v3 ul,
.site-builder-v3 ul {
  position: relative; }

#site-builder-v3 li,
.site-builder-v3 li {
  display: inline;
  margin: 0 6px 0 0; }

#site-builder-v3 .layout-form,
.site-builder-v3 .layout-form {
  max-width: 340px;
  margin-left: 0; }

#site-builder-v3 .button,
.site-builder-v3 .button {
  background-color: #c4c4c4;
  cursor: pointer;
  min-height: 25px;
  text-align: center;
  line-height: 200%;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 50px;
  width: 100%; }

#site-builder-v3 .form-control,
.site-builder-v3 .form-control {
  background-color: #FFFFFF !important;
  height: calc(2rem + 2px); }

#site-builder-v3 .blocks-popover-container .form-control,
.site-builder-v3 .blocks-popover-container .form-control {
  background-color: #FFFFFF !important;
  height: calc(1.5rem + 0px);
  padding: 1px; }

#site-builder-v3 .dots,
.site-builder-v3 .dots {
  background: #FFFFFF;
  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 20px;
  padding: 0 9px;
  position: absolute;
  cursor: pointer;
  top: 50%; }
  #site-builder-v3 .dots.left,
  .site-builder-v3 .dots.left {
    left: 55px;
    transform: translate(0, -53%); }
    #site-builder-v3 .dots.left.dots-center,
    .site-builder-v3 .dots.left.dots-center {
      left: 10px; }
  #site-builder-v3 .dots.right,
  .site-builder-v3 .dots.right {
    right: 55px;
    transform: translate(0, -53%); }
    #site-builder-v3 .dots.right.dots-center,
    .site-builder-v3 .dots.right.dots-center {
      right: 15px; }

#site-builder-v3 .menu-center,
.site-builder-v3 .menu-center {
  text-align: center; }

#site-builder-v3 .menu-left,
.site-builder-v3 .menu-left {
  text-align: left; }

#site-builder-v3 .menu-right,
.site-builder-v3 .menu-right {
  text-align: right; }

#site-builder-v3 .transparency-bg,
.site-builder-v3 .transparency-bg {
  background-color: #f0f0f0;
  border: none; }

#site-builder-v3 .solid-bg,
.site-builder-v3 .solid-bg {
  background-color: #FFFFFF; }

#site-builder-v3 .logo-menu,
.site-builder-v3 .logo-menu {
  position: relative;
  overflow: hidden;
  width: 65px; }

#site-builder-v3 .theme-popup-check,
.site-builder-v3 .theme-popup-check {
  cursor: pointer; }

#site-builder-v3 .page-home,
.site-builder-v3 .page-home {
  font-size: 20px;
  line-height: 24px;
  max-width: 255px;
  margin: 0 auto; }

#site-builder-v3 .page-page,
.site-builder-v3 .page-page {
  font-size: 20px;
  line-height: 24px;
  max-width: 310px;
  margin: 0 auto; }

#site-builder-v3 .btn-dark,
.site-builder-v3 .btn-dark {
  background: #4C7B81;
  border: 1px solid #CED4DA;
  font-weight: 700;
  width: 150px; }

#site-builder-v3 .btn-outline-dark,
.site-builder-v3 .btn-outline-dark {
  background: #FFFFFF;
  border: 1px solid #4C7B81;
  color: #3B585A;
  font-weight: 700;
  width: 150px; }

#site-builder-v3 .img-placeholder,
.site-builder-v3 .img-placeholder {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #F0F0F0;
  background-size: cover;
  text-align: center;
  position: relative; }
  #site-builder-v3 .img-placeholder.small-size,
  .site-builder-v3 .img-placeholder.small-size {
    height: 120px;
    width: 120px;
    margin: 0 auto; }
  #site-builder-v3 .img-placeholder.main,
  .site-builder-v3 .img-placeholder.main {
    height: 170px; }
  #site-builder-v3 .img-placeholder.doble, #site-builder-v3 .img-placeholder.medio,
  .site-builder-v3 .img-placeholder.doble,
  .site-builder-v3 .img-placeholder.medio {
    height: 110px; }
  #site-builder-v3 .img-placeholder.one-third,
  .site-builder-v3 .img-placeholder.one-third {
    height: calc(170px / 3); }
  #site-builder-v3 .img-placeholder.two-thirds,
  .site-builder-v3 .img-placeholder.two-thirds {
    height: calc(170px * 2 / 3); }
  #site-builder-v3 .img-placeholder.doble,
  .site-builder-v3 .img-placeholder.doble {
    max-width: 100%;
    margin: 0 auto; }

#site-builder-v3 .side-text-panel-left,
.site-builder-v3 .side-text-panel-left {
  max-width: 155px;
  margin-left: auto; }

#site-builder-v3 .side-text-panel-right,
.site-builder-v3 .side-text-panel-right {
  max-width: 155px;
  margin-left: 0; }

#site-builder-v3 .color-text,
.site-builder-v3 .color-text {
  color: #999999; }

#site-builder-v3 .color-subtitle,
.site-builder-v3 .color-subtitle {
  color: #666666; }

#site-builder-v3 .banners,
.site-builder-v3 .banners {
  position: relative;
  top: calc(50% - 25px);
  transform: translateY(-50%); }
  #site-builder-v3 .banners.banners-center,
  .site-builder-v3 .banners.banners-center {
    top: calc(50%); }

#site-builder-v3 .font-8,
.site-builder-v3 .font-8 {
  font-size: 8px; }

#site-builder-v3 .font-10,
.site-builder-v3 .font-10 {
  font-size: 10px; }

#site-builder-v3 .font-12,
.site-builder-v3 .font-12 {
  font-size: 12px; }

#site-builder-v3 .font-14,
.site-builder-v3 .font-14 {
  font-size: 14px; }

#site-builder-v3 .font-13,
.site-builder-v3 .font-13 {
  font-size: 13px; }

#site-builder-v3 .font-16,
.site-builder-v3 .font-16 {
  font-size: 16px; }

#site-builder-v3 .font-26,
.site-builder-v3 .font-26 {
  font-size: 26px; }

#site-builder-v3 .font-10,
.site-builder-v3 .font-10 {
  font-size: 10px; }

#site-builder-v3 .w-150,
.site-builder-v3 .w-150 {
  width: 150px; }

#site-builder-v3 .w-160,
.site-builder-v3 .w-160 {
  width: 160px; }

#site-builder-v3 .w-300,
.site-builder-v3 .w-300 {
  width: 300px; }

#site-builder-v3 .products-content .row-2,
.site-builder-v3 .products-content .row-2 {
  max-width: 100px;
  margin-left: auto;
  margin-right: auto; }

#site-builder-v3 .products-content .row-3,
.site-builder-v3 .products-content .row-3 {
  max-width: 150px;
  margin-left: auto;
  margin-right: auto; }

#site-builder-v3 .products-content .row-4,
.site-builder-v3 .products-content .row-4 {
  max-width: 210px;
  margin-left: auto;
  margin-right: auto; }

#site-builder-v3 .category-container,
.site-builder-v3 .category-container {
  max-width: 360;
  margin: 0 auto 40px; }

#site-builder-v3 .contact-panel span.icon,
.site-builder-v3 .contact-panel span.icon {
  position: absolute;
  left: 15px;
  height: 20px;
  width: 20px; }

#site-builder-v3 .btn-contact,
.site-builder-v3 .btn-contact {
  background-color: #0085FC;
  border-radius: 15px;
  line-height: 0;
  height: 20px;
  width: 100%; }

#site-builder-v3 textarea,
.site-builder-v3 textarea {
  resize: none;
  border: 1px solid #CED4DA; }

#site-builder-v3 .input-min-height,
.site-builder-v3 .input-min-height {
  height: 22px; }

#site-builder-v3 .w-120,
.site-builder-v3 .w-120 {
  max-width: 120px; }

#site-builder-v3 .w-160,
.site-builder-v3 .w-160 {
  max-width: 160px; }

#site-builder-v3 .carousel-caption,
.site-builder-v3 .carousel-caption {
  color: #000; }

#site-builder-v3 .carousel-control-next-icon,
.site-builder-v3 .carousel-control-next-icon {
  background-image: url("/assets/images/carrousel-arrow.svg"); }

#site-builder-v3 .carousel-control-prev-icon,
.site-builder-v3 .carousel-control-prev-icon {
  background-image: url("/assets/images/carrousel-arrow.svg");
  transform: rotate(180deg); }

#site-builder-v3 .cursor-pointer,
.site-builder-v3 .cursor-pointer {
  cursor: pointer; }

#site-builder-v3 .d-flex-box,
.site-builder-v3 .d-flex-box {
  width: 408px;
  padding-left: 12px;
  padding-right: 15px; }

#site-builder-v3 .d-flex-one,
.site-builder-v3 .d-flex-one {
  margin-right: 8px;
  flex: 1 1 auto; }

#site-builder-v3 .d-flex-two,
.site-builder-v3 .d-flex-two {
  width: 260px; }

#site-builder-v3 .d-flex-three,
.site-builder-v3 .d-flex-three {
  margin-left: 12px;
  width: 40px; }

#site-builder-v3 .header-button,
.site-builder-v3 .header-button {
  font-size: 8px;
  line-height: 180%;
  max-width: 60px;
  padding-top: 5px;
  padding-bottom: 5px; }

#site-builder-v3 .carousel-style-text-background .carousel-title,
.site-builder-v3 .carousel-style-text-background .carousel-title {
  font-size: 20px; }
  #site-builder-v3 .carousel-style-text-background .carousel-title .custom-input-container .content,
  .site-builder-v3 .carousel-style-text-background .carousel-title .custom-input-container .content {
    padding: 6px; }

#site-builder-v3 .carousel-style-text-background .carousel-subtitle,
.site-builder-v3 .carousel-style-text-background .carousel-subtitle {
  font-size: 14px; }
  #site-builder-v3 .carousel-style-text-background .carousel-subtitle .custom-input-container .content,
  .site-builder-v3 .carousel-style-text-background .carousel-subtitle .custom-input-container .content {
    padding: 6px; }

.slider-popover-container {
  max-width: 250px;
  width: 250px; }
  .slider-popover-container .ngx-slider .ngx-slider-pointer {
    background-color: #79C9D5 !important; }

.slider-icon {
  opacity: 0.7; }
  .slider-icon:hover {
    opacity: 1; }
  .slider-icon fa-icon svg {
    filter: drop-shadow(0px 0px 1px #FFFF99); }

.color-picker-icon,
.button-style-setter-icon {
  opacity: 0.7; }
  .color-picker-icon:hover,
  .button-style-setter-icon:hover {
    opacity: 1; }
  .color-picker-icon fa-icon svg,
  .button-style-setter-icon fa-icon svg {
    filter: drop-shadow(0px 0px 1px #fff); }

.button-style-setter-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px; }

.button-style-setter-popover-container {
  max-width: 350px;
  width: 350px; }

#site-builder-v3 ckeditor ol, #site-builder-v3 .inner-html ol {
  list-style-type: revert; }

#site-builder-v3 ckeditor ul, #site-builder-v3 .inner-html ul {
  list-style-type: revert;
  padding-left: revert; }

#site-builder-v3 ckeditor li, #site-builder-v3 .inner-html li {
  display: revert; }

.subscription-sections .nav-tabs .nav-item .nav-link {
  padding: 1rem; }

.blocks {
  position: relative; }
  .blocks.active:after {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    width: 6px;
    height: 50px;
    background-color: #E30613; }

.builder-block {
  background-color: #FFFFFF;
  max-width: 190px;
  margin: 0 auto;
  position: relative;
  cursor: pointer; }
  .builder-block.active {
    background: #79C9D5;
    border: 1px solid #000; }

.block-size-36 {
  padding-top: 8px;
  padding-bottom: 8px; }

.block-size-78 {
  padding-top: 19px;
  padding-bottom: 19px; }

.block-size-98 {
  padding-top: 39px;
  padding-bottom: 39px; }

.block-size-118 {
  padding-top: 49px;
  padding-bottom: 49px; }

.block-size-116 {
  padding-top: 48px;
  padding-bottom: 48px; }

.block-size-114 {
  padding-top: 47px;
  padding-bottom: 47px; }

.block-size-66 {
  padding-top: 23px;
  padding-bottom: 23px; }

.builder-dnd-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column; }

.builder-dnd-handler {
  position: absolute;
  right: 12px;
  bottom: 8px;
  cursor: move;
  font-size: 16px;
  color: #666; }

.cdk-drag-preview .builder-block {
  background: rgba(255, 255, 255, 0.7); }

.cdk-drag-placeholder .builder-block {
  background: #ccc;
  border: dotted 3px #999;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  min-height: 60px; }

.website-container .accordion {
  /* Animation Accordion effect */ }
  .website-container .accordion .card {
    border: none !important;
    box-shadow: none !important;
    margin-bottom: 14px; }
    .website-container .accordion .card.open::after {
      border-bottom: 1px solid #E30613; }
    .website-container .accordion .card:first-child {
      margin-bottom: 0px; }
      .website-container .accordion .card:first-child .card-body {
        background-color: #ffffff; }
      .website-container .accordion .card:first-child::before {
        content: '';
        position: absolute;
        height: 85%;
        padding-top: 0; }
    .website-container .accordion .card:first-child::after {
      content: '';
      position: absolute;
      width: 0; }
    .website-container .accordion .card:nth-last-child(1)::before {
      bottom: inherit;
      top: 0;
      height: 10px;
      padding-top: 0; }
    .website-container .accordion .card::before {
      content: '';
      position: absolute;
      bottom: -20px;
      left: -12px;
      height: calc(100% + 20px);
      border-left: 1px solid #000000; }
    .website-container .accordion .card::after {
      content: '';
      position: absolute;
      border-bottom: 1px solid #000000;
      left: -12px;
      top: 10px;
      width: 12px; }
    .website-container .accordion .card .card-header {
      background-color: #FFFFFF;
      border: none;
      padding: 0;
      position: relative; }
      .website-container .accordion .card .card-header span {
        color: #000;
        font-size: 0;
        margin-top: 0;
        position: relative;
        right: 0; }
        .website-container .accordion .card .card-header span.visible {
          font-size: inherit; }
    .website-container .accordion .card .card-body {
      background-color: #E4F6F8;
      padding: 0; }
    .website-container .accordion .card h5 {
      display: block;
      width: 100%; }
    .website-container .accordion .card .btn-link {
      color: #000000;
      display: block;
      font-size: 12px;
      font-family: 'Roboto', sans-serif;
      text-decoration: none;
      text-transform: none;
      padding: 0;
      width: 100%; }
    .website-container .accordion .card .page-name {
      text-transform: capitalize; }
      .website-container .accordion .card .page-name:hover {
        cursor: text; }
      .website-container .accordion .card .page-name.edit {
        border: 1px solid #CED4DA;
        padding: 3px 0px 3px 8px; }
  .website-container .accordion .collapse {
    transition: max-height .655s, opacity .355s ease-in-out;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    display: block !important; }
    .website-container .accordion .collapse.show {
      max-height: 100rem;
      opacity: 1; }

.scroll-on-y {
  max-height: calc(100vh - 50px);
  overflow-y: auto; }

.blocks-popover-container {
  border: 1px solid #CED4DA;
  box-sizing: border-box;
  margin-left: 30px;
  font-family: 'Roboto';
  padding: 12px 20px;
  max-width: 450px;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.25);
  border-radius: 0%;
  width: 100%;
  z-index: 1050; }
  .blocks-popover-container .dots-pop-class .popover-body {
    min-height: auto !important; }
    .blocks-popover-container .dots-pop-class .popover-body div {
      border: 1px solid #CED4DA;
      box-sizing: border-box;
      color: #666666;
      line-height: 200%;
      font-size: 11px;
      padding-left: 10px;
      cursor: pointer; }
      .blocks-popover-container .dots-pop-class .popover-body div:hover {
        background-color: #f0f0f0; }
  .blocks-popover-container .popover-header {
    background-color: #FFFFFF;
    font-family: 'Roboto';
    font-size: 14px;
    border: none;
    padding: 0px 0px 25px 0px; }
  .blocks-popover-container .popover-body {
    background-color: #FFFFFF;
    font-size: 12px;
    color: #000000;
    font-family: 'Roboto';
    padding: 0;
    min-height: 210px; }
  .blocks-popover-container .form-control {
    color: #495057;
    height: calc(1.5rem + 0px) !important;
    padding: 2px 15px;
    font-size: 12px; }
  .blocks-popover-container .arrow {
    display: none !important; }
  .blocks-popover-container .title {
    font-size: 14px; }
  .blocks-popover-container .palette {
    display: flex;
    margin: 0 auto; }
  .blocks-popover-container .square {
    width: 33px;
    height: 33px;
    cursor: pointer;
    margin-right: 18px;
    border: 1px solid #333;
    border-radius: 0.25rem; }
    .blocks-popover-container .square.active, .blocks-popover-container .square:focus {
      border-color: #0a58ca;
      box-shadow: 0 0 0 0.25rem #3184fd; }
  .blocks-popover-container .btn-close {
    max-width: 20px;
    font-size: 14px;
    margin-left: auto;
    cursor: pointer; }

.blocks-popover-container-wide {
  max-width: 800px; }
  .blocks-popover-container-wide.fixed {
    width: 800px; }

.blocks-popover-container-medium {
  max-width: 600px; }

.blocks-popover-container-half-wide {
  max-width: 400px; }

@media (max-width: 767px) {
  .blocks-popover-container {
    display: none; } }

.typeahead-container {
  position: relative; }
  .typeahead-container .dropdown-menu {
    font-size: 12px; }

.rate-card {
  height: 100%; }
  .rate-card .provider-logo {
    text-align: center; }
    .rate-card .provider-logo img {
      width: 100%;
      height: auto;
      max-width: 200px; }

.builder-faq-accordion .card {
  position: relative;
  margin-left: 25px;
  margin-bottom: 20px !important; }
  .builder-faq-accordion .card:first-of-type, .builder-faq-accordion .card:last-of-type {
    margin-bottom: 20px; }
  .builder-faq-accordion .card .circle-count {
    position: absolute;
    top: 16px;
    left: -25px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50%;
    border: 1px solid #ced4da;
    font-size: 12px;
    font-weight: 400;
    color: #888888;
    background-color: #fff;
    text-align: center; }
  .builder-faq-accordion .card .minus-plus-trigger-container {
    position: absolute;
    right: 10px;
    top: 22px; }
    .builder-faq-accordion .card .minus-plus-trigger-container .minus-plus-trigger {
      position: relative;
      width: 11px;
      height: 11px;
      /* Vertical line */
      /* horizontal line */ }
      .builder-faq-accordion .card .minus-plus-trigger-container .minus-plus-trigger:before, .builder-faq-accordion .card .minus-plus-trigger-container .minus-plus-trigger:after {
        content: "";
        position: absolute;
        background-color: #000;
        transition: transform 0.25s ease-out; }
      .builder-faq-accordion .card .minus-plus-trigger-container .minus-plus-trigger:before {
        top: 0;
        left: 50%;
        width: 1px;
        height: 100%;
        margin-left: 0px;
        transform: rotate(0deg); }
      .builder-faq-accordion .card .minus-plus-trigger-container .minus-plus-trigger:after {
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        margin-top: -1px;
        transform: rotate(0deg); }
      .builder-faq-accordion .card .minus-plus-trigger-container .minus-plus-trigger.expanded {
        cursor: pointer; }
        .builder-faq-accordion .card .minus-plus-trigger-container .minus-plus-trigger.expanded:before {
          transform: rotate(90deg); }
        .builder-faq-accordion .card .minus-plus-trigger-container .minus-plus-trigger.expanded:after {
          transform: rotate(180deg); }
  .builder-faq-accordion .card .card-header {
    padding: 8px 30px 8px 12px; }
    .builder-faq-accordion .card .card-header h5 {
      width: 100%;
      margin: 0;
      text-align: left; }
    .builder-faq-accordion .card .card-header .btn {
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      text-align: left;
      color: #000; }
      .builder-faq-accordion .card .card-header .btn .text {
        color: #000; }
    .builder-faq-accordion .card .card-header .btn-link:hover,
    .builder-faq-accordion .card .card-header .btn-link:focus {
      text-decoration: none;
      color: #000; }
    .builder-faq-accordion .card .card-header span {
      margin: 0;
      color: #000; }

.brightness-slider-container {
  position: absolute;
  z-index: 20; }
  .brightness-slider-container.top-left {
    top: 0;
    left: 0; }
  .brightness-slider-container.top-right {
    top: 0;
    right: 0; }
  .brightness-slider-container.top-center {
    top: 0;
    left: calc(50% - 11px); }
  .brightness-slider-container.bottom-right {
    bottom: 0;
    right: 0; }
  .brightness-slider-container.bottom-left {
    bottom: 0;
    left: 0; }
  .brightness-slider-container.bottom-center {
    bottom: 0;
    left: calc(50% - 11px); }
  .brightness-slider-container.center {
    top: calc(50% - 14px);
    left: calc(50% - 11px); }

.diamond-shape {
  transform: rotate(45deg);
  width: 1rem;
  height: 1rem; }

.circle-shape {
  border-radius: 50%;
  width: 1rem;
  height: 1rem; }

/* components/task end */
.card-body .new-task .fade-in-primary .cr {
  margin-top: 0; }

.task-right .accordion-block p {
  margin-bottom: 1rem; }

.task-right .media-left {
  padding-right: 20px;
  padding-left: 2px; }

.filter-bar .navbar .navbar-nav .dropdown-menu {
  position: absolute; }

/* components/task end */
/* components/editor/quill editor */
.quill-editor {
  min-height: 16em;
  max-height: 20em;
  overflow-y: auto; }

/* quill editor end*/
.dropdown-toggle.no-icon::after {
  display: none; }

.card .card-body.assign-user .dropdown-menu {
  top: 100%; }

/* components/file-upload/ start */
ngb-progressbar .progress-bar {
  height: 1rem !important;
  color: #fff; }

.ngb-drop-zone {
  min-height: 1.5rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border: dashed 1px;
  border-radius: 0.125rem; }

/* components/file-upload/ end */
/* map start */
agm-map, vector_map {
  width: 100%;
  height: 350px;
  display: flex; }

.jqvmap-label, #jqstooltip {
  position: absolute;
  display: none;
  border: solid 1px #CDCDCD;
  border-radius: 3px;
  background: #292929;
  color: white;
  font-family: sans-serif, Verdana;
  font-size: smaller;
  padding: 3px; }

.jqvmap-zoomin {
  position: absolute;
  left: 10px;
  border-radius: 3px;
  background: #292929;
  padding: 4px 10px;
  color: white;
  cursor: pointer;
  line-height: 20px;
  text-align: center;
  box-sizing: content-box;
  top: 10px; }

.jqvmap-zoomout {
  position: absolute;
  left: 10px;
  border-radius: 3px;
  background: #292929;
  padding: 4px 10px;
  color: white;
  cursor: pointer;
  line-height: 20px;
  text-align: center;
  box-sizing: content-box;
  top: 50px; }

/* map end */
.pcoded[vertical-nav-type="collapsed"] .pcoded-navigatio-lavel {
  display: none; }

/* animation start */
.main-img {
  position: static !important; }

/* animation end */
/* authentication start */
.auth-fluid {
  margin: 0 auto; }

/* authentication end */
.loader-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .loader-container .logo {
    background: url("/assets/images/loader.png") no-repeat center center;
    width: 64px;
    height: 64px; }
  .loader-container .spinner {
    margin: 35px auto 0;
    width: 70px;
    text-align: center; }
    .loader-container .spinner > div {
      width: 18px;
      height: 18px;
      background-color: #E30613;
      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
    .loader-container .spinner .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s; }
    .loader-container .spinner .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

/* bootstrap 4-beta css start*/
.pull-0 {
  right: auto; }

.pull-1 {
  right: 8.333333%; }

.pull-2 {
  right: 16.666667%; }

.pull-3 {
  right: 25%; }

.pull-4 {
  right: 33.333333%; }

.pull-5 {
  right: 41.666667%; }

.pull-6 {
  right: 50%; }

.pull-7 {
  right: 58.333333%; }

.pull-8 {
  right: 66.666667%; }

.pull-9 {
  right: 75%; }

.pull-10 {
  right: 83.333333%; }

.pull-11 {
  right: 91.666667%; }

.pull-12 {
  right: 100%; }

.push-0 {
  left: auto; }

.push-1 {
  left: 8.333333%; }

.push-2 {
  left: 16.666667%; }

.push-3 {
  left: 25%; }

.push-4 {
  left: 33.333333%; }

.push-5 {
  left: 41.666667%; }

.push-6 {
  left: 50%; }

.push-7 {
  left: 58.333333%; }

.push-8 {
  left: 66.666667%; }

.push-9 {
  left: 75%; }

.push-10 {
  left: 83.333333%; }

.push-11 {
  left: 91.666667%; }

.push-12 {
  left: 100%; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-sm-0 {
    right: auto; }
  .pull-sm-1 {
    right: 8.333333%; }
  .pull-sm-2 {
    right: 16.666667%; }
  .pull-sm-3 {
    right: 25%; }
  .pull-sm-4 {
    right: 33.333333%; }
  .pull-sm-5 {
    right: 41.666667%; }
  .pull-sm-6 {
    right: 50%; }
  .pull-sm-7 {
    right: 58.333333%; }
  .pull-sm-8 {
    right: 66.666667%; }
  .pull-sm-9 {
    right: 75%; }
  .pull-sm-10 {
    right: 83.333333%; }
  .pull-sm-11 {
    right: 91.666667%; }
  .pull-sm-12 {
    right: 100%; }
  .push-sm-0 {
    left: auto; }
  .push-sm-1 {
    left: 8.333333%; }
  .push-sm-2 {
    left: 16.666667%; }
  .push-sm-3 {
    left: 25%; }
  .push-sm-4 {
    left: 33.333333%; }
  .push-sm-5 {
    left: 41.666667%; }
  .push-sm-6 {
    left: 50%; }
  .push-sm-7 {
    left: 58.333333%; }
  .push-sm-8 {
    left: 66.666667%; }
  .push-sm-9 {
    left: 75%; }
  .push-sm-10 {
    left: 83.333333%; }
  .push-sm-11 {
    left: 91.666667%; }
  .push-sm-12 {
    left: 100%; }
  .offset-sm-0 {
    margin-left: 0%; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-md-0 {
    right: auto; }
  .pull-md-1 {
    right: 8.333333%; }
  .pull-md-2 {
    right: 16.666667%; }
  .pull-md-3 {
    right: 25%; }
  .pull-md-4 {
    right: 33.333333%; }
  .pull-md-5 {
    right: 41.666667%; }
  .pull-md-6 {
    right: 50%; }
  .pull-md-7 {
    right: 58.333333%; }
  .pull-md-8 {
    right: 66.666667%; }
  .pull-md-9 {
    right: 75%; }
  .pull-md-10 {
    right: 83.333333%; }
  .pull-md-11 {
    right: 91.666667%; }
  .pull-md-12 {
    right: 100%; }
  .push-md-0 {
    left: auto; }
  .push-md-1 {
    left: 8.333333%; }
  .push-md-2 {
    left: 16.666667%; }
  .push-md-3 {
    left: 25%; }
  .push-md-4 {
    left: 33.333333%; }
  .push-md-5 {
    left: 41.666667%; }
  .push-md-6 {
    left: 50%; }
  .push-md-7 {
    left: 58.333333%; }
  .push-md-8 {
    left: 66.666667%; }
  .push-md-9 {
    left: 75%; }
  .push-md-10 {
    left: 83.333333%; }
  .push-md-11 {
    left: 91.666667%; }
  .push-md-12 {
    left: 100%; }
  .offset-md-0 {
    margin-left: 0%; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-lg-0 {
    right: auto; }
  .pull-lg-1 {
    right: 8.333333%; }
  .pull-lg-2 {
    right: 16.666667%; }
  .pull-lg-3 {
    right: 25%; }
  .pull-lg-4 {
    right: 33.333333%; }
  .pull-lg-5 {
    right: 41.666667%; }
  .pull-lg-6 {
    right: 50%; }
  .pull-lg-7 {
    right: 58.333333%; }
  .pull-lg-8 {
    right: 66.666667%; }
  .pull-lg-9 {
    right: 75%; }
  .pull-lg-10 {
    right: 83.333333%; }
  .pull-lg-11 {
    right: 91.666667%; }
  .pull-lg-12 {
    right: 100%; }
  .push-lg-0 {
    left: auto; }
  .push-lg-1 {
    left: 8.333333%; }
  .push-lg-2 {
    left: 16.666667%; }
  .push-lg-3 {
    left: 25%; }
  .push-lg-4 {
    left: 33.333333%; }
  .push-lg-5 {
    left: 41.666667%; }
  .push-lg-6 {
    left: 50%; }
  .push-lg-7 {
    left: 58.333333%; }
  .push-lg-8 {
    left: 66.666667%; }
  .push-lg-9 {
    left: 75%; }
  .push-lg-10 {
    left: 83.333333%; }
  .push-lg-11 {
    left: 91.666667%; }
  .push-lg-12 {
    left: 100%; }
  .offset-lg-0 {
    margin-left: 0%; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.333333%;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.666667%;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.666667%;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.333333%;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.666667%;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.333333%;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.666667%;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .pull-xl-0 {
    right: auto; }
  .pull-xl-1 {
    right: 8.333333%; }
  .pull-xl-2 {
    right: 16.666667%; }
  .pull-xl-3 {
    right: 25%; }
  .pull-xl-4 {
    right: 33.333333%; }
  .pull-xl-5 {
    right: 41.666667%; }
  .pull-xl-6 {
    right: 50%; }
  .pull-xl-7 {
    right: 58.333333%; }
  .pull-xl-8 {
    right: 66.666667%; }
  .pull-xl-9 {
    right: 75%; }
  .pull-xl-10 {
    right: 83.333333%; }
  .pull-xl-11 {
    right: 91.666667%; }
  .pull-xl-12 {
    right: 100%; }
  .push-xl-0 {
    left: auto; }
  .push-xl-1 {
    left: 8.333333%; }
  .push-xl-2 {
    left: 16.666667%; }
  .push-xl-3 {
    left: 25%; }
  .push-xl-4 {
    left: 33.333333%; }
  .push-xl-5 {
    left: 41.666667%; }
  .push-xl-6 {
    left: 50%; }
  .push-xl-7 {
    left: 58.333333%; }
  .push-xl-8 {
    left: 66.666667%; }
  .push-xl-9 {
    left: 75%; }
  .push-xl-10 {
    left: 83.333333%; }
  .push-xl-11 {
    left: 91.666667%; }
  .push-xl-12 {
    left: 100%; }
  .offset-xl-0 {
    margin-left: 0%; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

/* bootstrap 4-beta css end*/
/** Base styles **/
.btn-icon-only,
.btn-icon-only:hover,
.btn-icon-only:active,
.btn-icon-only:focus {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: 0;
  background: transparent;
  padding: 5px; }

.btn-icon-only.btn-danger {
  color: #e74c3c; }

.table-row-cursor tbody > tr:hover {
  cursor: pointer; }

.hover-pointer:hover {
  cursor: pointer; }

.login-card .sheyla {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: black;
  height: 56px;
  line-height: 56px;
  padding: 0 15px; }
  .login-card .sheyla img {
    max-width: 60%; }

.login-card .sheyla-headline {
  font-family: 'Nunito', sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #666;
  margin-top: 10px;
  padding: 0 15px; }

.login-card .email-label {
  font-size: 1.15rem; }

.sheyla-guide {
  font-family: 'Nunito', sans-serif !important; }
  .sheyla-guide p,
  .sheyla-guide li {
    font-size: 14px; }
  .sheyla-guide img {
    max-width: 100%; }

a {
  color: #37a1b1; }
  a:hover {
    color: #1f5a63; }

.pcoded-main-container {
  background: #f9fafb; }

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #79C9D5;
  border-color: #79C9D5; }

.btn-outline-primary {
  border-color: #79C9D5;
  color: #37a1b1; }

.btn-info {
  background-color: #37a1b1;
  border-color: #37a1b1; }

.text-primary {
  color: #37a1b1 !important; }

.card {
  background: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }
  .card .ngx-datatable.material {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.3); }
  .card.card-border-primary {
    border-top: 4px solid #79C9D5; }

.accordion > .card {
  margin-bottom: 0; }

.pcoded .pcoded-header[header-theme="theme-white"] {
  background: #79C9D5;
  color: #fff; }

.pcoded .pcoded-header .navbar-logo[navbar-theme="theme-white"] {
  background-color: #79C9D5; }

.pcoded .pcoded-navbar[active-item-theme="theme-white"] .pcoded-item > li.active > a:before {
  border-left-color: #E30613 !important; }

.pcoded .pcoded-navbar .pcoded-inner-navbar.main-menu {
  border-right: 1px solid rgba(0, 0, 0, 0.1); }

.pcoded .pcoded-navbar .pcoded-item > li > a {
  padding: 5px 15px; }
  .pcoded .pcoded-navbar .pcoded-item > li > a > .pcoded-micon {
    font-size: 18px;
    padding-right: 10px; }

.pcoded .pcoded-navbar .pcoded-item.pcoded-micon-color-0 > li > a > .pcoded-micon {
  color: #3498db; }

.pcoded .pcoded-navbar .pcoded-item.pcoded-micon-color-1 > li > a > .pcoded-micon {
  color: #1abc9c; }

.pcoded .pcoded-navbar .pcoded-item.pcoded-micon-color-2 > li > a > .pcoded-micon {
  color: #f39c12; }

.pcoded .pcoded-navbar .pcoded-item.pcoded-micon-color-3 > li > a > .pcoded-micon {
  color: #34495e; }

.pcoded .pcoded-navbar .pcoded-item.pcoded-micon-color-4 > li > a > .pcoded-micon {
  color: #e74c3c; }

.pcoded .pcoded-navbar .pcoded-item.pcoded-micon-color-5 > li > a > .pcoded-micon {
  color: #9b59b6; }

.pcoded .pcoded-navbar .pcoded-item.pcoded-micon-color-6 > li > a > .pcoded-micon {
  color: #2ecc71; }

.pcoded .pcoded-navbar .pcoded-item.pcoded-micon-color-7 > li > a > .pcoded-micon {
  color: #d35400; }

#printable-area .card {
  box-shadow: none !important; }

.text-muted {
  color: #444 !important; }

.sub-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important; }

.border {
  border: 1px solid rgba(0, 0, 0, 0.2) !important; }

.form-control {
  border-color: rgba(0, 0, 0, 0.2);
  border-width: 1px; }

.ngx-datatable.material {
  border: 1px solid rgba(0, 0, 0, 0.2); }
  .ngx-datatable.material .datatable-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
    .ngx-datatable.material .datatable-header .datatable-header-cell {
      color: #212529; }
    .ngx-datatable.material .datatable-header .resize-handle {
      border-right: 1px solid rgba(0, 0, 0, 0.2); }
  .ngx-datatable.material .datatable-footer {
    color: #212529;
    border-top: 1px solid rgba(0, 0, 0, 0.2); }

body {
  background: #fff;
  font-family: 'Nunito', sans-serif; }

.input-group + .messages {
  margin-top: -1.25em; }

.input-group-append .btn-outline-secondary,
.input-group-prepend .btn-outline-secondary {
  border-color: #ced4da; }

:focus {
  outline: none; }

::-moz-focus-inner {
  border: 0; }

@media only screen and (max-width: 992px) {
  .main-body .page-wrapper {
    padding: 0; } }

.header-navbar .navbar-wrapper .navbar-container .header-notification .profile-notification a,
.header-navbar .navbar-wrapper .navbar-container .mega-menu-top .profile-notification a {
  padding: 0; }

.list-circle {
  list-style: circle;
  padding-left: 3rem;
  margin-bottom: 1rem; }

.table-fixed {
  table-layout: fixed; }
  .table-fixed td,
  .table-fixed th {
    word-wrap: break-word;
    white-space: normal; }

/* #toast-container > div {
  opacity:1;
}
*/
/* Submenu badges */
.pcoded .pcoded-navbar .pcoded-item li a .pcoded-mtext.has-badge {
  padding-right: 44px; }

.pcoded .pcoded-navbar .pcoded-item li a .pcoded-badge {
  right: 5px; }

/* Se evita el scroll al menu estableciendo el alto mínimo del contenido al disponible en la pantalla */
.pcoded-content {
  min-height: 100vh; }

ul.styled {
  list-style-type: disc;
  padding-left: 2em; }

.pcoded-inner-content {
  padding-bottom: 140px; }

.cursor-pointer {
  cursor: pointer; }

.custom-file input[type="file"] {
  height: 100%; }

.nav-tabs {
  border-bottom: none; }

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
  color: #666;
  background-color: white;
  border: none; }
  .nav-tabs .nav-item.show .nav-link.active, .nav-tabs .nav-link.active {
    font-weight: bold;
    color: #79C9D5;
    background-color: white;
    border-bottom: 5px solid #E30613; }

.editable {
  border: 1px solid transparent; }
  .editable:hover {
    border: 1px solid #cccccc; }

.swal-primary {
  background-color: #79C9D5 !important;
  border-radius: 0% !important;
  border: 2px solid #79C9D5 !important; }

.swal-secondary {
  background-color: white !important;
  border: 2px solid #79C9D5 !important;
  color: #79C9D5 !important;
  border-radius: 0% !important; }
  .swal-secondary:hover {
    background-color: #79C9D5 !important;
    color: white !important; }

.no-shadow {
  box-shadow: none !important; }

.form-group .ng2-tag-input {
  border: 1px solid #ced4da !important; }

.facebook-color {
  color: #3b5998; }

.instagram-color {
  color: #C13584; }

.twitter-color {
  color: #00acee; }
