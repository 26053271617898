.brightness-slider-container {
  position: absolute;
  z-index: 20;

  &.top-left {
    top: 0;
    left: 0;
  }

  &.top-right {
    top: 0;
    right: 0;
  }

  &.top-center {
    top: 0;
    left: calc(50% - 11px); // Cambiar los pixeles por la mitad del ancho del ícono
  }

  &.bottom-right {
    bottom: 0;
    right: 0;
  }

  &.bottom-left {
    bottom: 0;
    left: 0;
  }

  &.bottom-center {
    bottom: 0;
    left: calc(50% - 11px); // Cambiar los pixeles por la mitad del ancho del ícono
  }

  &.center {
    top: calc(50% - 14px); // Cambiar los pixeles por la mitad del alto del ícono
    left: calc(50% - 11px); // Cambiar los pixeles por la mitad del ancho del ícono
  }
}
