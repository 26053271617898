#toasty .toast {
    width: auto;
    height: 55px;
}

#toasty .toast .close-button {
    top: calc(50% - 12px);
    opacity: 1;
}

#toasty .toast .toast-text {
    padding: 5px 35px 5px 60px;
}

/* components/advance/notify*/
.small-icon .icon {
    width: auto !important;
    height: auto !important;
}

.no-icon .icon {
    display: none;
}

.simple-notification-wrapper {
    z-index: 10001 !important;
}
