.wrapper-card {
  display: flex;
  flex-wrap: wrap;
  margin-top: 76px; // 52px + 24px del plan destacado
}

.subscription-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 100%;
  max-width: 260px;
  margin: 0;
  padding: 34px 7px 34px 16px;
  background: #FFFFFF;
  border: 1px solid rgba(33, 37, 41, 0.2);
  box-sizing: border-box;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
  text-align: center;
  transition: all 0.5s ease-in-out;


  &.popular {
    position: relative;
    padding: 34px 7px;
    border: 4px solid #79C9D5;
    border-radius: 10px;
    margin-top: -24px;
  }

  &.escale {
    padding: 34px 7px;
  }

  &:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
}

.card-ribbon {
  position: absolute;
  overflow: hidden;
  top: -10px;
  left: -10px;
  width: 114px;
  height: 112px;

  span {
    position: absolute;
    display: block;
    width: 160px;
    padding: 10px 0;
    background-color: #3498db;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 13px;
    text-transform: uppercase;
    text-align: center;
    left: -35px;
    top: 25px;
    transform: rotate(-45deg);
  }

  &::before,
  &::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
    border-top-color: transparent;
    border-left-color: transparent;
  }

  &::before {
    top: 0;
    right: 0;
  }

  &::after {
    bottom: 0;
    left: 0;
  }
}

.card-title {
  h3 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #E30613;
  }

  h4 {
    color: rgba(0, 0, 0, 0.6);
  }
}

.card-price {
  margin: 0;

  h1 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: #666666;

    .currency,
    .recurrency,
    .taxes {
      font-size: 14px;
    }

    .info {
      font-size: 16px;
    }
  }
}

.card-description {
  flex: 1;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

  ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    color: #000;
    margin: 0 0 16px;
    text-align: left;

    &::before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f00c";
      padding: 0 10px 0 0;
      color: #79C9D5;
    }

    &.no::before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f00d";
      padding: 0 10px 0 0;
      color: rgba(255, 0, 0, 0.25);
    }
  }
}

.card-action {
  margin: 25px;

  button,
  .btn {
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 12px 0;
    border: 1px solid #5DA3B1;
    background: #5DA3B1;
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #5DA3B1;
      box-shadow: 0 2px 4px darken(#5DA3B1, 20);
      font-size: 20px;
    }
  }
}

.section-title {
  font-size: 1.3em;
}

.reference {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  text-align: left;
  margin-top: 24px;
}

@media screen and (max-width: 1000px) {
  .wrapper-card {
    display: flex;
    flex-wrap: wrap;
    max-width: auto;
    width: 90%;
  }

  .subscription-card,
  .subscription-card.popular {
    margin: 0;
    margin-bottom: 30px;
  }
}

.escale-image {
  position: relative;
  margin: 8px auto;
  text-align: center;

  img {
    width: 160px;
    max-width: 100%;
    height: auto;
  }
}

.escale-trademark {
  margin: 0 auto 4px auto;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: #666666;
}

.tailormade-solutions {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #666666;
}

.plan-payment-section-container {
  margin-top: 70px;

  .card {
    .card-header {
      padding: 26px 28px;

      h1 {
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: normal;
        margin: 0;
        color: #000;
      }
    }

    .card-body {
      padding: 26px 28px;
    }
  }

}
