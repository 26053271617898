.input-color-picker {
  position: relative;

  label {
    position: relative;
    height: 100%;
    cursor: pointer;
  }

  .color {
    width: 34px;
    height: 34px;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: inline-block;
    vertical-align: middle;
    outline: none;
    border: 1px solid transparent;
  }

  input.selector {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 34px;
    height: 34px;
    outline: none;
    opacity: 0;
  }
}
