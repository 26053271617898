/* components/tables/data-table start */
ngx-datatable.data-table{
    border: 1px solid #eceeef;
}
.data-table datatable-header{
    border-bottom: 2px solid #ccc;
}
.data-table  datatable-footer{
    border-top: 2px solid #ccc;
    padding: 0 0.75rem;
    background-color: #eceeef;
    box-shadow: inset 0 2px 4px -2px rgba(0, 0, 0, 0.15);
}
.data-table .empty-row{
    padding: 0.75rem;
    text-align: center;
}
.data-table .datatable-row-wrapper:nth-child(odd){
    background-color: rgba(0,0,0,.05);
}
.data-table datatable-body-cell{
    padding: 0.75rem;
}
.data-table datatable-body-cell + datatable-body-cell{
    border-left: 1px solid #eceeef;
}
.data-table datatable-header-cell .datatable-header-cell-label{
    padding: .75rem 30px .75rem .75rem;
}
.data-table datatable-header-cell + datatable-header-cell{
    border-left: 1px solid #eceeef;
}
.data-table datatable-header-cell.sortable:before,.data-table datatable-header-cell.sortable:after{
    font-family: 'IcoFont'!important;
    position: absolute;
    top: 10px;
    color: #ccc;
    font-size: 20px;
}
// .datatable-header-cell-wrapper,.datatable-header-cell-label {
//   display: block;
// }
.data-table datatable-header-cell.sortable:after{
    content: "\eb53";
    right: 10px;
}
.data-table datatable-header-cell.sortable:before{
    content: "\eb56";
    right: 15px;
}
.data-table datatable-header-cell.sortable.sort-active.sort-desc:after{
    color: #666;
}
.data-table datatable-header-cell.sortable.sort-active.sort-asc:before{
    color: #666;
}

.data-table.scroll .datatable-body, .data-table.fullscreen .datatable-body {
    height: 500px !important;
}

.data-table datatable-scroller {
    width: auto !important;
}

.datatable-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    font-size: 16px;
}
.datatable-footer .page-count {
    line-height: 50px;
    height: 50px;
    padding: 0 1.2rem;
}
.datatable-footer .datatable-pager {
    margin: 0 10px;
}
.datatable-footer .datatable-pager li {
    vertical-align: middle;
}
.datatable-footer .datatable-pager li.disabled a {
    color: rgba(0, 0, 0, 0.26) !important;
    background-color: transparent !important;
}
.datatable-footer .datatable-pager li.active a {
    background-color: #0073AA;
    color: #fff;
}
.datatable-footer .datatable-pager a {
    padding: 0 6px;
    border-radius: 3px;
    margin: 6px 3px;
    text-align: center;
    vertical-align: top;
    color: rgba(0, 0, 0, 0.54);
    text-decoration: none;
    vertical-align: bottom;
}
.datatable-footer .datatable-pager a:hover {
    color: rgba(0, 0, 0, 0.75);
    background-color: rgba(158, 158, 158, 0.2);
}
.datatable-footer .datatable-pager .datatable-icon-left,
.datatable-footer .datatable-pager .datatable-icon-skip,
.datatable-footer .datatable-pager .datatable-icon-right,
.datatable-footer .datatable-pager .datatable-icon-prev {
    font-size: 20px;
    line-height: 20px;
    padding: 0 3px;
}
.datatable-icon-right:before{
    content: "\eb27";
    font-family: 'icofont';
    font-style: normal;
}
.datatable-icon-skip:before{
    content: "\eb3a";
    font-family: 'icofont';
    font-style: normal;
}
.datatable-icon-left:before{
    content: "\eb26";
    font-family: 'icofont';
    font-style: normal;
}
.datatable-icon-prev:before{
    content: "\eb39";
    font-family: 'icofont';
    font-style: normal;
}
.dt-desc th,.dt-desc td{
    padding: .75rem;
}
.dt-desc{
    padding: 0.45rem 50px;
}
.dt-desc table{
    min-width: 400px;
}
.datatable-row-detail{
    background-color: #f9f9f9;
    box-shadow: inset 0px 2px 9px -6px rgba(0, 0, 0, 0.68);
}

.expandable datatable-body-cell + datatable-body-cell,.expandable datatable-header-cell + datatable-header-cell{
    width: calc(calc(100% - 50px) / 5) !important;
}

.expandable .datatable-body-row,.expandable .datatable-header-inner,.datatable-row-center,.datatable-scroll{
    width: 100% !important;
}
.data-table.expandable datatable-scroller {
    width: 100% !important;
}

.ngx-datatable.data-table.single-selection .datatable-body-row.active:hover .datatable-row-group,
.ngx-datatable.data-table.multi-selection .datatable-body-row.active:hover .datatable-row-group,
.ngx-datatable.data-table.single-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.data-table.multi-selection .datatable-body-row.active .datatable-row-group,
.selection-cell .datatable-body-cell.active {
    background: #0073aa !important;
    color: #FFF !important;
}

.selected-column label {
    margin-bottom: 5px;
}
.dt-chk label{
    margin-bottom: 0;
}
.dt-chk .datatable-body-cell {
    line-height: 1 !important;
}

.full-data-search {
    margin-left: 0.5em;
    display: inline-block;
    width: auto !important;
}

.full-data-show-entry {
    width: 75px;
    display: inline-block;
}

.full-data-table .glyphicon{
    font-family: IcoFont;
    position: absolute;
    right: 14px;
    font-size: 20px;
    top: 9px;
}
.full-data-table .glyphicon.glyphicon-triangle-bottom:before{
    content: "\eb53";
}
.full-data-table .glyphicon.glyphicon-triangle-top:before{
    content: "\eb56";
}

.full-data-table th {
    position: relative;
}
/* components/tables/data-table end */

/* Helper classes */

@keyframes cd-scroll-right {
    0%,
    100% {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    50% {
        -webkit-transform: translateX(3px);
        -moz-transform: translateX(3px);
        -ms-transform: translateX(3px);
        -o-transform: translateX(3px);
        transform: translateX(3px);
    }
}

.table-scroll-container {
    position: relative;
    max-width: 100%;

    &::before {
        display: none;
        content: '';
        position: absolute;
        top: 0;
        right: -1px;
        height: 100%;
        width: 35px;
        background: linear-gradient(to left, #fff 10%, rgba(255, 255, 255, 0));
        z-index: 5;
        pointer-events: none;
    }

    .scroll-container {
        overflow-y: auto;
    }

    .scroll-right {
        display: none;
        width: 16px;
        height: 16px;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -8px;
        background: url(/assets/images/small-arrow.svg?1400497079) no-repeat center center;
        z-index: 6;
        animation: cd-scroll-right 1.5s infinite;
    }
}

@media only screen and (max-width: 768px) {
    .table-scroll-container {
        &::before,
        .scroll-right {
            display: block;
        }
    }
}

/* End helper classes */
