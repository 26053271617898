/** Base styles **/
.btn-icon-only,
.btn-icon-only:hover,
.btn-icon-only:active,
.btn-icon-only:focus {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: 0;
    background: transparent;
    padding: 5px;
}

.btn-icon-only {
    &.btn-danger {
        color: #e74c3c;
    }
}

.table-row-cursor {
    tbody > tr:hover { cursor: pointer; }
}

.hover-pointer {
    &:hover { cursor: pointer; }
}